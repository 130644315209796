import React from 'react'
import './products.css'

const StorageCabinets = () => {
    return (
        <>
            <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li>Our Products</li>
                                            <li>Storage Cabinet</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* page title end */}
            {/* product section start */}
            <br />
            <section className="pro_details_section">
                <div className="container">
            {/* product-1 start */}
            <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="disimg">
                        {/* Add the image URL for the Block Storage Cabinet product */}
                        <img src="https://5.imimg.com/data5/MR/SV/ES/ANDROID-3790006/product-jpeg-500x500.jpg" className="img-fluid" alt="Block Storage Cabinet" />
                        <div className="overlay_dis">
                            <a href="/contact" className="popup-modal">Enquiry Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12">
                    <div className="prodistext">
                        <h3 id="blockStorageCabinet">Block Storage Cabinet</h3>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Approx. Price</td>
                                    <td>Rs 50,000 / Piece</td>
                                </tr>
                                <tr>
                                    <td>Minimum Order Quantity</td>
                                    <td>1 Piece</td>
                                </tr>
                                <tr>
                                    <td>Material</td>
                                    <td>CRC Sheet</td>
                                </tr>
                                <tr>
                                    <td>Design Type</td>
                                    <td>Customized</td>
                                </tr>
                                <tr>
                                    <td>Color</td>
                                    <td>White</td>
                                </tr>
                                <tr>
                                    <td>Brand</td>
                                    <td>r.k</td>
                                </tr>
                                <tr>
                                    <td>Country of Origin</td>
                                    <td>Made in India</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="extraContent1" style={{ display: 'none' }}>
                            <h4>R.K. BLOCK CABINET</h4>
                            <p>Block cabinet made of CRC steel duty enamel painted the internal drawers are also of M/s duty enamel painted each drawer having 1 to13 compartments with capacity of 26 block approximately index card folders and handles are provided on each drawer the cabinet has smooth one door opening for dust free storage handle on front panel with lock and key. In this new block cabinet we are providing on additional drawers downside and have put 4 wheel rollers in its legs so that can be moved here & there. Available in following capacities Capacity (a) 10,000 Blocks 20 Drawer Capacity (b) 20,000 Blocks 40 Drawer Capacity (b) 25,000 Blocks 50 Drawer Capacity (b) 50,000 Blocks 100 Drawer</p>
                        </div>
                        <button onClick={() => {
                            const content = document.getElementById('extraContent1');
                            const button = document.getElementById('toggleButton1');
                            if (content.style.display === 'none') {
                                content.style.display = 'block';
                                button.textContent = 'Read Less';
                            } else {
                                content.style.display = 'none';
                                button.textContent = 'Read More';
                            }
                        }} id="toggleButton1">Read More</button>
                    </div>
                </div>
            </div>
            {/* product-1 end */}
            <br />
            {/* product-2 start */}
            <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="disimg">
                        {/* Add the image URL for the Paraffin Block Storage Cabinet product */}
                        <img src="https://3.imimg.com/data3/WJ/ON/MY-3790006/block-cabinet-500x500.jpg" className="img-fluid" alt="Paraffin Block Storage Cabinet" />
                        <div className="overlay_dis">
                            <a href="/contact" className="popup-modal">Enquiry Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12">
                    <div className="prodistext">
                        <h3 id="paraffinBlockStorageCabinet">Paraffin Block Storage Cabinet</h3>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Approx. Price</td>
                                    <td>Rs 50,000 / Piece</td>
                                </tr>
                                <tr>
                                    <td>Design Type</td>
                                    <td>Standard</td>
                                </tr>
                                <tr>
                                    <td>Color</td>
                                    <td>WHITE</td>
                                </tr>
                                <tr>
                                    <td>Usage/Application</td>
                                    <td>IT Servers</td>
                                </tr>
                                <tr>
                                    <td>No Of Racks</td>
                                    <td>2</td>
                                </tr>
                                <tr>
                                    <td>Material</td>
                                    <td>Mild Steel</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="extraContent2" style={{ display: 'none' }}>
                            <p>We offer our clients a comprehensive range of Block Cabinet (For Residual Blocks). These are manufactured using rich quality mild steel that is duly powder coated. The offered range have 4 to 8 compartments with capacity of 125 blocks and the drawers are made of MS duly powder coated. Each draw also have index card holder and handle. For dust free storage, the offered cabinet has the door with locking system. These are available in the capacities of 20,000 blocks 40 drawers.</p>
                            <h4>Additional Information:</h4>
                            <p>Pay Mode Terms: T/T (Bank Transfer)</p>
                        </div>
                        <button onClick={() => {
                            const content = document.getElementById('extraContent2');
                            const button = document.getElementById('toggleButton2');
                            if (content.style.display === 'none') {
                                content.style.display = 'block';
                                button.textContent = 'Read Less';
                            } else {
                                content.style.display = 'none';
                                button.textContent = 'Read More';
                            }
                        }} id="toggleButton2">Read More</button>
                    </div>
                </div>
            </div>
            {/* product-2 end */}
            <br />
            {/* product-3 start */}
            <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="disimg">
                        {/* Add the image URL for the Tissue Block Storage Cabinet product */}
                        <img src="https://3.imimg.com/data3/DX/XU/MY-3790006/block-cabinet-500x500.jpg" className="img-fluid" alt="Tissue Block Storage Cabinet" />
                        <div className="overlay_dis">
                            <a href="/contact" className="popup-modal">Enquiry Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12">
                    <div className="prodistext">
                        <h3 id="tissueBlockStorageCabinet">Tissue Block Storage Cabinet</h3>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Approx. Price</td>
                                    <td>Rs 50,000 / Piece</td>
                                </tr>
                                <tr>
                                    <td>Design Type</td>
                                    <td>Standard</td>
                                </tr>
                                <tr>
                                    <td>Usage/Application</td>
                                    <td>Storage</td>
                                </tr>
                                <tr>
                                    <td>Color</td>
                                    <td>White</td>
                                </tr>
                                <tr>
                                    <td>Material</td>
                                    <td>Mild Steel</td>
                                </tr>
                                <tr>
                                    <td>Country of Origin</td>
                                    <td>Made in India</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="extraContent3" style={{ display: 'none' }}>
                            <p>Block cabinets made of CRC mild steel duty Powder Coating painted the internal drawers are also made of M/s CRC duty Powder Coating painted each drawer having 1 to 13 compartments with each drawer capacity of 506 blocks approximately each drawer with the index card folders and handles are provided on each drawer the cabinet has smooth two door opening for dust free storage handle on front panel with lock and key. and we are providing 4 wheel rollers so that can be move here & there.</p>
                            <p>Available in following capacities:</p>
                            <ul>
                                <li>10,000 Blocks 20 Drawers</li>
                                <li>20,000 Blocks 40 Drawers</li>
                                <li>25,000 Blocks 50 Drawers</li>
                            </ul>
                            <h4>Additional Information:</h4>
                            <p>Pay Mode Terms: T/T (Bank Transfer)</p>
                            <p>Delivery Time: 20-25 Days</p>
                        </div>
                        <button onClick={() => {
                            const content = document.getElementById('extraContent3');
                            const button = document.getElementById('toggleButton3');
                            if (content.style.display === 'none') {
                                content.style.display = 'block';
                                button.textContent = 'Read Less';
                            } else {
                                content.style.display = 'none';
                                button.textContent = 'Read More';
                            }
                        }} id="toggleButton3">Read More</button>
                    </div>
                </div>
            </div>
            {/* product-3 end */}
            <br />
            {/* product-4 start */}
            <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="disimg">
                        {/* Add the image URL for the Slide Storage Cabinet product */}
                        <img src="https://4.imimg.com/data4/AB/NP/MY-3790006/sli-500x500.jpg" className="img-fluid" alt="Slide Storage Cabinet" />
                        <div className="overlay_dis">
                            <a href="/contact" className="popup-modal">Enquiry Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12">
                    <div className="prodistext">
                        <h3 id="slideStorageCabinet">Slide Storage Cabinet</h3>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Approx. Price</td>
                                    <td>Rs 35,000 / Piece</td>
                                </tr>
                                <tr>
                                    <td>Design Type</td>
                                    <td>Standard</td>
                                </tr>
                                <tr>
                                    <td>Color</td>
                                    <td>White</td>
                                </tr>
                                <tr>
                                    <td>Material</td>
                                    <td>PVC</td>
                                </tr>
                                <tr>
                                    <td>Usage/Application</td>
                                    <td>Industrial</td>
                                </tr>
                                <tr>
                                    <td>Brand</td>
                                    <td>R.K.</td>
                                </tr>
                                <tr>
                                    <td>Door Type</td>
                                    <td>Single</td>
                                </tr>
                                <tr>
                                    <td>Height</td>
                                    <td>4 Feet</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="extraContent4" style={{ display: 'none' }}>
                            <p>We bring forth an impeccable gamut of Micro Slide Cabinet. These are safe and easy to handle because of 75 × 25 mm slides. Manufactured using optimum quality mild steel sheet so as to maintain its quality standard. The offered range is enamel painted and have smooth working doors fitted with handle and locking system is provided. For keeping 75x75mm slide in vertical position one after the other of steel attractive spray finish. The cabinet has 480x75mm drawer and teas slide in vertical position. Each drawer moves in a slot and is completely removable for easy lifting. The cabinet accommodates maximum number of slide for optimum space utilization. Complete with lock and key.</p>
                            <p>In this new block cabinet we are providing on additional drawers downside and have put 4 wheel rollers in its legs so that can be moved here & there.</p>
                            <p>These slide cabinet are available capacity in:</p>
                            <ul>
                                <li>10,000 slides</li>
                                <li>20,000 slides</li>
                                <li>25,000 slides</li>
                                <li>50,000 slides</li>
                                <li>100,000 slides</li>
                            </ul>
                        </div>
                        <button onClick={() => {
                            const content = document.getElementById('extraContent4');
                            const button = document.getElementById('toggleButton4');
                            if (content.style.display === 'none') {
                                content.style.display = 'block';
                                button.textContent = 'Read Less';
                            } else {
                                content.style.display = 'none';
                                button.textContent = 'Read More';
                            }
                        }} id="toggleButton4">Read More</button>
                    </div>
                </div>
            </div>
            {/* product-4 end */}
            <br />
            {/* product-5 start */}
            <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="disimg">
                        {/* Add the image URL for the Wax Block Storage Cabinets product */}
                        <img src="https://4.imimg.com/data4/LA/DS/MY-3790006/block-500x500.jpg" className="img-fluid" alt="Wax Block Storage Cabinets" />
                        <div className="overlay_dis">
                            <a href="/contact" className="popup-modal">Enquiry Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12">
                    <div className="prodistext">
                        <h3 id="waxBlockStorageCabinets">Wax Block Storage Cabinets</h3>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Approx. Price</td>
                                    <td>Rs 50,000 / Piece</td>
                                </tr>
                                <tr>
                                    <td>Usage/Application</td>
                                    <td>Industrial</td>
                                </tr>
                                <tr>
                                    <td>Material</td>
                                    <td>PVC</td>
                                </tr>
                                <tr>
                                    <td>Color</td>
                                    <td>White</td>
                                </tr>
                                <tr>
                                    <td>Height</td>
                                    <td>2 Feet</td>
                                </tr>
                                <tr>
                                    <td>Door Type</td>
                                    <td>Single</td>
                                </tr>
                                <tr>
                                    <td>Shape</td>
                                    <td>Rectangular</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="extraContent5" style={{ display: 'none' }}>
                            <p>R.K. BLOCK CABINET</p>
                            <p>Block cabinet made of CRC steel duty enamel painted the internal drawers are also of M/s duty enamel painted each drawer having 1 to13 compartments with capacity of 26 block approximately index card folders and handles are provided on each drawer the cabinet has smooth one door opening for dust free storage handle on front panel with lock and key.</p>
                            <p>In this new block cabinet we are providing on additional drawers downside and have put 4 wheel rollers in its legs so that can be moved here & there.</p>
                            <p>Available in following capacities:</p>
                            <ul>
                                <li>Capacity (a) 10,000 Blocks 30 Drawer</li>
                                <li>Capacity (b) 21,000 Blocks 60 Drawer</li>
                            </ul>
                        </div>
                        <button onClick={() => {
                            const content = document.getElementById('extraContent5');
                            const button = document.getElementById('toggleButton5');
                            if (content.style.display === 'none') {
                                content.style.display = 'block';
                                button.textContent = 'Read Less';
                            } else {
                                content.style.display = 'none';
                                button.textContent = 'Read More';
                            }
                        }} id="toggleButton5">Read More</button>
                    </div>
                </div>
            </div>
            {/* product-5 end */}
            <br />

</div>
</section>
        </>
    )
}

export default StorageCabinets
