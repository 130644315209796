import React, { useState } from "react";
import { OurProduct } from "../data/products";
import { Link } from "react-router-dom";
import "./Product.css";

const Product = () => {
  const [isExpanded] = useState(false);

  return (
    <>
     <section className="page_title_mkp_section">
          <div className="page_ti_overly_mkp_div">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                  <div className="page_title_tax_mkp_main_div">
                    <div className="page_title_tax_mkp_div">
                      {/* <h1> Our Products</h1> */}
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li>Our Products</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </section>
        <br/>
      <section className="recent padding">
        <div className="container">
          <div className="row">
            {OurProduct.map((val, index) => {
              const { cover, description, name } = val;
              const shortText = description.slice(0, 42) + "..."; // Adjust as needed

              return (
                <div className="col-lg-4" key={index}>
                  <div className="box shadow">
                    <div className="img ">
                      <a href={val.path}>
                       <img src={cover} alt="" style={{ width: '100%', height: '300px', cursor: 'pointer', objectFit: 'contain' }} />
                      </a>
                    </div>
                    <div className="text" >
                      <div className="category flex">
                        <span>{name}</span>
                      </div>
                      <p>
                        <i></i> {isExpanded ? description : shortText}
                      </p>
                      <Link to={val.path}>
                        <button
                          style={{
                            color: "black",
                            borderRadius: "10px",
                            background: "#ffffff",
                            padding: "2px",
                            
                          }}
                        >
                          {"View All"}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <br/>
    </>
  );
};

export default Product;
