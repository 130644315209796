import React from 'react'
import './products.css';
const SlideStainingTray = () => {
  return (
   <>
   <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Slide Staining Tray</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* product */}
            <br />
            <section className="pro_details_section">
                <div className="container">
                    
            {/* product start */}
            <div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Slide Staining Tray product */}
      <img src="https://5.imimg.com/data5/SELLER/Default/2021/10/RF/BE/JE/3790006/slide-staining-tray-500x500.jpg" className="img-fluid" alt="Slide Staining Tray" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="slideStainingTray">Slide Staining Tray</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 35,000 / Piece</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Hospital</td>
          </tr>
          <tr>
            <td>Automation Grade</td>
            <td>Automatic</td>
          </tr>
          <tr>
            <td>Frequency</td>
            <td>50/60 Hz</td>
          </tr>
          <tr>
            <td>Power Source</td>
            <td>Electric</td>
          </tr>
          <tr>
            <td>Voltage</td>
            <td>210V</td>
          </tr>
          <tr>
            <td>Country of Origin</td>
            <td>Made in India</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent" style={{ display: 'none' }}>
        <p>Staining slide racks are constructed of durable stainless steel with long rails. They hold up to 30 slides and fits sinks or trays up to stain, rinse, drain and dry quickly and easily</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent');
        const button = document.getElementById('toggleButton');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton">Read More</button>
    </div>
  </div>
</div>
<br/>
</div>
</section>
   </>
  )
}

export default SlideStainingTray
