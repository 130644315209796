import React from 'react'
import './products.css';
const GrossingKnife = () => {
  return (
    <>
     <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Grossing Knife</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* products */}
            <br />
            <section className="pro_details_section">
                <div className="container">
            {/* product start */}
           <div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Surgical Grossing Knife product */}
      <img src="https://5.imimg.com/data5/SELLER/Default/2023/12/371493777/BT/YP/BS/3790006/surgical-grossing-knife-500x500.jpeg" className="img-fluid" alt="Surgical Grossing Knife" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="surgicalGrossingKnife">Surgical Grossing Knife</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 10,000 / Piece</td>
          </tr>
          <tr>
            <td>Minimum Order Quantity</td>
            <td>5 Piece</td>
          </tr>
          <tr>
            <td>Automation Grade</td>
            <td>Manual</td>
          </tr>
          <tr>
            <td>Product Type</td>
            <td>Biochemical Analysis, Biology Lab Equipment, Blood Analysis System</td>
          </tr>
          <tr>
            <td>Condition</td>
            <td>New</td>
          </tr>
          <tr>
            <td>Blade Type</td>
            <td>Straight</td>
          </tr>
          <tr>
            <td>Handle Type</td>
            <td>Plastic</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent" style={{ display: 'none' }}>
        <p>R.K. Grossing Blade Holder</p>
        <p>Knife Plastic handle with socket stainless steel shaft. Very clever knife handles that fit ordinary microtome knife. In this way, you can reuse the microtome blade that you have needed for sectioning. Preferably used for cutting and dissection. Accessories, microtome knife blade</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent');
        const button = document.getElementById('toggleButton');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton">Read More</button>
    </div>
  </div>
</div> 
<br />
</div>
</section>
    </>
  )
}

export default GrossingKnife
