// Products services-----------------------------------------------------------
export const OurProduct = [
    {
      id: 1,
      cover: "https://5.imimg.com/data5/SELLER/Default/2024/2/388874047/YY/ND/ZI/9049015/embedding-cassette-clump-holder-250x250.jpg",
      description: "A variety of equipment used in a hospital laboratory",
      name: "Hospital Laboratory",
      path:"/hospital-laboratory-equipments"
    },
    {
      id: 2,
      cover: "https://5.imimg.com/data5/SELLER/Default/2023/12/371470373/HB/OD/ID/3790006/tissue-embedding-mouldes-250x250.jpg",
      description: "laboratory equipment used in a hospital",
      name: "Laboratory Equipments",
      path:"/laboratory-equipments"
    },
    {
        id: 3,
        cover: "https://5.imimg.com/data5/RL/VO/VJ/ANDROID-3790006/product-jpeg-250x250.jpg",
        description: "storage cabinet used in a hospital laboratory",
        name: "Storage Cabinet",
        path: "/StorageCabinet"
      },
    {
      id: 4,
      cover: "https://5.imimg.com/data5/UB/PO/MY-3790006/microscope-slide-storage-cabinet-250x250.jpg",
      description: "slide cabinets used in a hospital laboratory",
      name: "Slide Cabinets",
      path:"/Hardwood"
    },
    {
      id: 5,
      cover: "https://3.imimg.com/data3/VO/KD/MY-3790006/tissue-flotation-bath-in-digital-250x250.jpg",
      description: "Tissue Flotation Bath used in a hospital laboratory",
      name: "Tissue Flotation Bath",
      path:"/TissueFlotationBath"
    },
    {
      id: 6,
      cover: "https://5.imimg.com/data5/SELLER/Default/2023/12/371490425/AF/MA/CL/3790006/wax-bath-machine-250x250.jpg",
      description: "wax bath used in a hospital laboratory",
      name: "Wax Bath",
      path:"/wax-bath"
    },
    {
      id: 7,
      cover: "https://4.imimg.com/data4/JJ/VJ/MY-3790006/tissue-cassettes-base-molds-250x250.jpg",
      description: "Tissue Cassettes Base Molds used in a hospital laboratory",
      name: "Tissue Cassettes Base Molds",
      path:"/tissue-cassettes-base-molds"
    },
    {
      id: 7,
      cover: "https://5.imimg.com/data5/SELLER/Default/2021/10/RF/BE/JE/3790006/slide-staining-tray-250x250.jpg",
      description: "Slide staining tray used in a hospital laboratory",
      name: "Slide Staining Tray",
      path: "/slide-staining-tray"
    },
    {
      id: 8,
      cover: "https://3.imimg.com/data3/XV/FB/MY-3790006/rotary-microtome-250x250.jpg",
      description: "microtome used in a hospital laboratory",
      name: "Microtome",
      path: "/microtome"
    },
   
    {
      id: 10,
      cover: "https://5.imimg.com/data5/JY/GW/MY-3790006/20170323_172820-250x250.jpg",
      description: "Cabnet Card Holder used in a hospital laboratory",
      name: "Cabinet Card Holder",
      path: "/cabinet-card-holder"
    },
    {
      id: 11,
      cover: "https://5.imimg.com/data5/SELLER/Default/2023/12/371493777/BT/YP/BS/3790006/surgical-grossing-knife-250x250.jpeg",
      description: "Grossing Knife used in a hospital laboratory",
      name: "Groosing Knife",
      path: "/groosing-knife"
    },
    {
      id: 12,
      cover: "https://5.imimg.com/data5/SELLER/Default/2022/10/ET/ZL/GT/3790006/new-product-250x250.jpeg",
      description: "Embedding Cassette Clump Holder used in a hospital laboratory",
      name: "Embedding Cassette",
      path: "/embedding-cassette"
    }
  ]
