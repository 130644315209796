import React from 'react'
import './products.css';
const Waxbath = () => {
  return (
    <>
    <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Wax Bath</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* products */}
            
            {/* product start */}
            <section className="pro_details_section">
                <div className="container">
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Wax Bath Machine product */}
      <img src="https://5.imimg.com/data5/SELLER/Default/2023/12/371490425/AF/MA/CL/3790006/wax-bath-machine-500x500.jpg" className="img-fluid" alt="Wax Bath Machine" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="waxBathMachine">Wax Bath Machine</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 40,000 / Piece</td>
          </tr>
          <tr>
            <td>Shape</td>
            <td>Rectangular</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
          <tr>
            <td>Voltage</td>
            <td>220V</td>
          </tr>
          <tr>
            <td>Frequency</td>
            <td>50/60 Hz</td>
          </tr>
          <tr>
            <td>Automation Grade</td>
            <td>Automatic</td>
          </tr>
          <tr>
            <td>Phase</td>
            <td>Three</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>Mild Steel</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent" style={{ display: 'none' }}>
        <p>We specialize in providing our clients with an immaculate array of Automatic Wax Bath. In order to maintain constant temperature 50 to 65 deg celsius, these are manufactured using quality stainless steel for paraffin bath, which is double walled with built in heating element and EGO Thermostat. These can be used in independent unit for wax melting and are further used in health medicine.</p>
        <p>Features: Automatic thermostat controller, Maintain constant temperature, Simple to operate product</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent');
        const button = document.getElementById('toggleButton');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton">Read More</button>
    </div>
  </div>
</div>
{/* product end */}
</div>
</section>

    </>
  )
}

export default Waxbath
