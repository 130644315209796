import React from 'react'

const Galleryhome = () => {
  return (
    <div>
      {/* <h1> Gallery Coming Soon......</h1> */}
    </div>
  )
}

export default Galleryhome
