import React from 'react'
import './products.css'

const SlideCabinets = () => {
  return (
    <>
      <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li>Our Products</li>
                                            <li>Slide Cabinet</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* end */}
            <br/>
            <section className="pro_details_section">
                <div className="container">
            {/* product section */}
            {/* product-1 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Microscope Slide Storage Cabinet product */}
      <img src="https://5.imimg.com/data5/UB/PO/MY-3790006/microscope-slide-storage-cabinet-500x500.jpg" className="img-fluid" alt="Microscope Slide Storage Cabinet" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="microscopeSlideStorageCabinet">Microscope Slide Storage Cabinet</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 35,000 / Piece</td>
          </tr>
          <tr>
            <td>Design Type</td>
            <td>Standard</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>PVC</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Industrial</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>3 Feet</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent1" style={{ display: 'none' }}>
        <p>We bring forth an impeccable gamut of Micro Slide Cabinet. These are safe and easy to handle because of 75 × 25 mm slides. Manufactured using optimum quality CRC mild steel sheet so as to maintain its quality standard. The offered range is Powder Coating painted and have smooth working doors fitted with handle and locking system is provided. For keeping 75x25mm slide in vertical position one after the other of steel attractive Powder Coating finish. The cabinet has 480x75mm drawer and teas slide in vertical position. Each drawer Capacity 315 Slides moves in a slot and is completely removable for easy lifting. The cabinet accommodates maximum number of slide for optimum space utilization. Complete with lock and key. These slide cabinet are available in 10,000 slides 20,000 slides 25,000 slides 50,000 slides 100,000 slides</p>
        <p>Additional Information: Pay Mode Terms: T/T (Bank Transfer)</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent1');
        const button = document.getElementById('toggleButton1');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton1">Read More</button>
    </div>
  </div>
</div>
{/* product-1 end */}
<br/>
{/* product-2 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Horizontal Slide Cabinets product */}
      <img src="https://5.imimg.com/data5/SELLER/Default/2021/6/FQ/UF/IA/3790006/horizontal-slide-cabinet-500x500.jpeg" className="img-fluid" alt="Horizontal Slide Cabinets" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="horizontalSlideCabinets">Horizontal Slide Cabinets</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 50,000 / Piece</td>
          </tr>
          <tr>
            <td>Design Type</td>
            <td>Customized</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Industrial</td>
          </tr>
          <tr>
            <td>Door Type</td>
            <td>Single</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>5 Feet</td>
          </tr>
          <tr>
            <td>Country of Origin</td>
            <td>Made in India</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent2" style={{ display: 'none' }}>
        <p>R.K. slide cabinet HORIZONTAL mANNER</p>
        <p>For safe and easy handling of 75 × 25 mm slides. Made of CRC mild sheet. Well designed for its appearance, performance and durability. Powder coating paint outer finish with smoothly working doors fitted with handle, lock and key. The and slide tray made of CRC Mild Steel Steel for holding 75 × 25 mm slides, have been arranged separately so as not to disturb other compartments while taking out or putting in the slides. The usual size of the tray is 350 × 210 × 35 mm and is nicely fitted with uniformly slotted carrier in four rows, each holding 50 slides i.e. each tray hold 75/300 slides in horizontal position and is fitted with index and holder. Slide holding slotted rows are numbered 1 to 50 to identify the slides</p>
        <p>Drawer Capacity 300 slide each tray</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent2');
        const button = document.getElementById('toggleButton2');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton2">Read More</button>
    </div>
  </div>
</div>
{/* product-2 end */}
<br/>
{/* product-3 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Microscopic Slide Cabinet product */}
      <img src="https://5.imimg.com/data5/SELLER/Default/2023/1/NB/HR/LB/3790006/slide-cabinets-500x500.jpg" className="img-fluid" alt="Microscopic Slide Cabinet" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="microscopicSlideCabinet">Microscopic Slide Cabinet</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 30,000 / Piece</td>
          </tr>
          <tr>
            <td>Minimum Order Quantity</td>
            <td>1 Piece</td>
          </tr>
          <tr>
            <td>Design Type</td>
            <td>Customized</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>CRC MILD STEEL</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.k</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>ANY</td>
          </tr>
          <tr>
            <td>Country of Origin</td>
            <td>Made in India</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent3" style={{ display: 'none' }}>
        <p>We provide the best quality slide cabinets in the industry by using the modern technology tools.</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent3');
        const button = document.getElementById('toggleButton3');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton3">Read More</button>
    </div>
  </div>
</div>
{/* product-3 end */}
<br/>
{/* product-4 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Micro Slide Cabinet product */}
      <img src="https://3.imimg.com/data3/DO/SM/MY-3790006/micro-slide-cabinet-500x500.jpg" className="img-fluid" alt="Micro Slide Cabinet" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="microSlideCabinet">Micro Slide Cabinet</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 40,000 / Piece</td>
          </tr>
          <tr>
            <td>Minimum Order Quantity</td>
            <td>2 Piece</td>
          </tr>
          <tr>
            <td>Design Type</td>
            <td>Standard</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>PVC</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>3 Feet</td>
          </tr>
          <tr>
            <td>Door Type</td>
            <td>Single</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent4" style={{ display: 'none' }}>
        <p>We bring forth an impeccable gamut of Micro Slide Cabinet. These are safe and easy to handle because of 75 × 25 mm slides. Manufactured using optimum quality CRC mild steel sheet so as to maintain its quality standard. The offered range is Powder Coating painted and have smooth working doors fitted with handle and locking system is provided. For keeping 75x25mm slide in vertical position one after the other of steel attractive Powder Coating finish. The cabinet has 480x75mm drawer and teas slide in vertical position. Each drawer Capacity 315 Slides moves in a slot and is completely removable for easy lifting. The cabinet accommodates maximum number of slide for optimum space utilization. Complete with lock and key.</p>
        <p>These slide cabinet are available in</p>
        <ul>
          <li>10,000 slides</li>
          <li>20,000 slides</li>
          <li>25,000 slides</li>
          <li>50,000 slides</li>
          <li>100,000 slides</li>
        </ul>
        <p>Additional Information:</p>
        <p>Pay Mode Terms: T/T (Bank Transfer)</p>
        <p>Delivery Time: 15 - 20 Days</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent4');
        const button = document.getElementById('toggleButton4');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton4">Read More</button>
    </div>
  </div>
</div>
{/* product-4 end */}
<br/>
{/* product-5 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Slide Storage Cabinet product */}
      <img src="https://3.imimg.com/data3/LT/JR/MY-3790006/slide-cabinet-500x500.jpg" className="img-fluid" alt="Slide Storage Cabinet" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="slideStorageCabinet">Slide Storage Cabinet</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 1 Lakh / Piece</td>
          </tr>
          <tr>
            <td>Minimum Order Quantity</td>
            <td>1 Piece</td>
          </tr>
          <tr>
            <td>Design Type</td>
            <td>Standard</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>PVC</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Office</td>
          </tr>
          <tr>
            <td>Door Type</td>
            <td>Single</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>3 Feet</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent5" style={{ display: 'none' }}>
        <p>For keeping 75 x 25 mm slide in vertical position one after the other of steel attractive Powder Coating finish. The cabinet has 480 x 75 mm drawer and teas slide in vertical position. Each Capacity 315 Slides drawer moves in a slot and is completely removable for easy lifting. The cabinet accommodates maximum number of slide for optimum space utilization. Complete with lock and key. and we are providing 4 wheel rollers so that can be move here & there</p>
        <p>Available in following capacities:</p>
        <ul>
          <li>25,000 Slides 80 Drawers</li>
          <li>50,000 slides 160 Drawer</li>
          <li>1,00,000 slides 320 Drawer</li>
        </ul>
        <p>Additional Information:</p>
        <p>Delivery Time: 20-25 Days</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent5');
        const button = document.getElementById('toggleButton5');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton5">Read More</button>
    </div>
  </div>
</div>
{/* product-5 end */}
<br/>
{/* product-6 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Micro Slide Cabinet 100000 Slides product */}
      <img src="https://4.imimg.com/data4/LA/SX/MY-3790006/cabinet-500x500.jpg" className="img-fluid" alt="Micro Slide Cabinet 100000 Slides" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="microSlideCabinet100000Slides">Micro Slide Cabinet 100000 Slides</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 2 Lakh / Piece</td>
          </tr>
          <tr>
            <td>Design Type</td>
            <td>Standard</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>PVC</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Industrial</td>
          </tr>
          <tr>
            <td>Door Type</td>
            <td>Single</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>3 Feet</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent6" style={{ display: 'none' }}>
        <p>Micro Slide Cabinet. These are safe and easy to handle because of 75 × 25 mm slides. Manufactured using optimum quality CRC mild steel sheet so as to maintain its quality standard. The offered range is Powder Coating painted and have smooth working doors fitted with handle and locking system is provided. For keeping 75x25mm slide in vertical position one after the other of steel attractive spray finish. The cabinet has 480x75mm drawer and teas slide in vertical position. Each drawer moves in a slot and is completely removable for easy lifting. The cabinet accommodates maximum number of slide for optimum space utilization. Complete with lock and key.</p>
        <p>In this cabinet we are providing 4 wheel rollers so that can be moved here & there.</p>
        <p>These slide cabinet are available capacity in</p>
        <ul>
          <li>100,000 Slides with 336 Drawer</li>
        </ul>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent6');
        const button = document.getElementById('toggleButton6');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton6">Read More</button>
    </div>
  </div>
</div>
{/* product-6 end */}
<br/>
</div>
</section>
    </>
  )
}

export default SlideCabinets
