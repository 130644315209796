// import React from "react";
import bannerImage1 from '../banner/1.png';
import bannerImage2 from '../banner/2.png';
import bannerImage3 from '../banner/3.png';
import imgw from '../banner/imgw.jpg';
import bannerImage4 from '../banner/m1.png';
import bannerImage5 from '../banner/m2.png';
import bannerImage6 from '../banner/m3.png';
import { Link } from "react-router-dom";
import Testimonials from "./Testimonials";
import Galleryhome from "./Galleryhome";
import React, { useEffect, useRef } from 'react';
import { Carousel } from 'react-bootstrap';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from '../banner/about.png';


const Home = () => {
  function SliderSection() {
    const carouselRef = useRef(null);

    useEffect(() => {
      const interval = setInterval(() => {
        if (carouselRef.current) {
          carouselRef.current.next();
        }
      }, 3000); // Adjust the interval duration as needed (in milliseconds)

      return () => clearInterval(interval);
    }, []);

    return (
      <section className="slider_section">
        <Carousel
          id="carouselExampleIndicators"
          ref={carouselRef}
          indicators={false}
          controls={false}
          pause={false}
        >
          <Carousel.Item>
            <img src={bannerImage1} className="d-block w-100" alt="..." />
          </Carousel.Item>
          <Carousel.Item>
            <img src={bannerImage2} className="d-block w-100" alt="..." />
          </Carousel.Item>
          <Carousel.Item>
            <img src={bannerImage3} className="d-block w-100" alt="..." />
          </Carousel.Item>
        </Carousel>
      </section>
    );
  }

  return (
    <>
      <SliderSection />

      {/* mobile slider banner */}

      <section className="slider_section2">
        <div
          id="carouselExampleIndicator"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="1000"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicator"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleIndicator"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleIndicator"
              data-bs-slide-to={2}
              aria-label="Slide 3"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={bannerImage4}
                className="d-block w-100"
                alt="..."
                // style={{ height: "600px" }}
              />
            </div>
            <div className="carousel-item">
              <img
                src={bannerImage5}
                className="d-block w-100"
                alt="..."
                // style={{ height: "600px" }}
              />
            </div>
            <div className="carousel-item">
              <img
                src={bannerImage6}
                className="d-block w-100"
                alt="..."
                // style={{ height: "600px" }}
              />
            </div>
          </div>
          <div className="btn_left_right">
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicator"
              data-bs-slide="prev"
            >
              <i class="bx bx-left-arrow"></i>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicator"
              data-bs-slide="next"
            >
              <i class="bx bx-right-arrow"></i>
            </button>
          </div>
        </div>
      </section>
      {/* slider section end */}

      <div>
        {/* about us section start */}
        <section className="about_us_section">
          <div className="container">
            <div className="row">
              {/* col start */}
              <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                <div
                  className="about_img_main_div"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  data-aos-offset={0}
                >
                  <div className="about_vd_div">
                    <img
                      src={img}
                      alt="Tissue Flotation Bath"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              {/* col end */}
              {/* col start */}
              <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                <div
                  className="about_content_div"
                  data-aos="fade-down"
                  data-aos-duration={1200}
                  data-aos-offset={0}
                >
                  <h1>
                    Welcome to<span> Rk Scientific</span>{" "}
                  </h1>
                  <h3 style={{ color: 'rgb(240, 54, 55)' }}>Leading Manufacturer, Supplier and Exporter of</h3>
                  <h4>
                    Hospital Laboratory Equipments, Slide Cabinets, Wax Bath,
                    Slide Staining Tray, etc.
                  </h4>
                  <p>
                    Established in the year 2005, We, “R. K. Scientific
                    Instrument Centre”, are a prominent Manufacturer,
                    Wholesaler, Trader And Exporter of Hospital Laboratory
                    Equipments, Slide Cabinets, Wax Bath, Slide Staining Tray,
                    etc. This entire range of products is manufactured using
                    finest grade raw material procured from vendors of the
                    market.
                  </p>
                  {/* <b>Quality Assurance</b> */}
                  <p>
                    These equipment are rigorously tested on varied quality
                    parameters before the dispatch to ensure that these are in
                    conformation with industry standards & norms. These
                    equipment are widely used in various medical centers,
                    hospitals, clinics and nursing homes.
                  </p>
                  <div className="about_btn_div">
                    <Link to="About"> Read More</Link>
                  </div>
                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </section>
        {/* about us section end */}

        {/* ====================why choose us section================= */}
        <section className="why_choose_us_section">
          <div className="container">
            <div className="row align-items-center">
              {/* col s */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div
                  className="why_choose_txt"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-offset={1}
                  data-aos-duration={800}
                >
                  <h4>Why Choose Us?</h4>
                  <p>
                    <b>
                      Our company always believes in offering high grade quality
                      products to our valued clients all across the market.
                    </b>
                  </p>
                </div>
                {/* why_us flx div */}
                <div
                  className="why_choose_us_div_flx"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-offset={1}
                  data-aos-duration={1000}
                >
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bx bx-building-house" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="why_us_div_txt_div">
                        <h5>Infratech</h5>
                        <p>
                          Our infrastructure is equipped with the latest
                          technology to produce high-quality hospital equipment.
                          We continuously upgrade our infrastructure to meet the
                          rising demands in the medical industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bx bx-comment-detail" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="why_us_div_txt_div">
                      <h5>Commitment</h5>
                        <p>
                          We are committed to increasing efficiency and raising
                          the standard of living. We stand by our products and
                          services and ensure they meet the expectations of our
                          clients and patients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* end */}
                {/* why_us flx div */}
                <div
                  className="why_choose_us_div_flx"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-offset={1}
                  data-aos-duration={500}
                >
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bx bx-unite" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="why_us_div_txt_div">
                        <h5>Quality Assurance</h5>
                        <p>
                          We ensure all our hospital products and medical
                          accessories meet the highest quality standards. Our
                          approach is focused on patient's health and safety,
                          and we strive to provide products that aid in better
                          patient outcomes and reduced risks.
                        </p>
                        
                        
                        <div className="certifcat_btn_div">
                          <a href="about">
                            Read More{" "}
                            <i className="bx bx-chevrons-right bx-tada" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end */}
              </div>
              {/* col end */}
              {/* col s */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="why_us_img_div">
                  <img
                    src="https://5.imimg.com/data5/SELLER/Default/2024/2/388874047/YY/ND/ZI/9049015/embedding-cassette-clump-holder-500x500.jpg"
                    alt
                    className="img-fluid"
                  />

                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </section>
        {/* ====================why choose us section================= */}
        {/* why R.K. Scientific sec start */}
        <section className="why_sectin_md">
          <div className="why_md_ovr">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-7 col-sm-12 col-12">
                  <div className="why_md_txt_div">
                    <h3>Why R.K. Scientific Instrument Centre.</h3>
                    <p>
                      At R.K. Scientific Instrument Centre, our commitment to
                      providing exceptional customer service is what sets us
                      apart from the competition. Guided by a straight set of
                      principles, the R.K. Scientific team works continuously
                      towards ensuring an effortless experience for all clients.
                    </p>
                    <div className="why_mdl_btn">
                      <Link to="contact">
                        Enquiry Now ! <i className="bx bx-mail-send bx-tada" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* why R.K. Scientific sec end */}

     
        {/* end section */}

        {/* why choose us section s */}
        <div className="whychoose_us_sec2">
          <div className="whu_us_ovrlay_bg2">
            <div className="container">
              <div className="row align-items-center">
                {/* col  */}
                <div className="col-xxl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  {/*  */}
                  <div
                    className="why_us_txt_div_m2"
                    data-aos="fade-down"
                    data-aos-duration={1000}
                    data-aos-offset={0}
                  >
                    <h4>Exceeding Your Expectations</h4>
                    <p>
                      Our diverse product range is designed to cater to the
                      unique needs of our customers. We ensure product
                      consistency by conducting rigorous testing during
                      production and delivery.
                    </p>
                  </div>
                  {/*  */}
                </div>
                {/* col enmd */}
                {/* col img */}
                <div className="col-xxl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div
                    className="why_us_cnt_img_box_m2"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                    data-aos-offset={0}
                  >
                    {/* <img src="../images/qty01.jpeg" alt="" class="img-fluid"> */}
                    <img
                      src={imgw}
                      alt="Infratech"
                      width="100%"
                    />
                    <div className="overly_whyus_img_dd2">
                      <div className="overlay_qty">
                        {/* <img src="../images/qtyms01.png" alt="" class="img-fluid">
                         */}
                        <img
                          src="https://5.imimg.com/data5/SELLER/Default/2023/8/338817407/PS/SU/RC/3790006/manual-rotary-microtome-500x500.png"
                          alt="Infratech"
                          width="100%"
                        />
                        <div className="overly_qty">
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* col img */}
                {/* col img */}
                <div className="col-xxl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  {/*  */}
                  <div
                    className="why_us_txt_div_m2"
                    data-aos="fade-down"
                    data-aos-duration={1900}
                    data-aos-offset={0}
                  >
                    <h4>Punctual Delivery</h4>
                    <p>
                      Our dedicated and skilled professionals work tirelessly to
                      ensure customer satisfaction. We have streamlined our
                      processes to undertake large-scale production projects and
                      deliver high-volume orders within the agreed timeframe.
                    </p>
                  </div>
                  {/*  */}
                </div>
                {/* col img */}
              </div>
            </div>
          </div>
        </div>
        {/* why choose us section end */}

        {/* counter section start */}
        <section className="counter_section">
          <div className="counter_section_ovr">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="main_div_counter">
                    <div className="counter_icon_div">
                      <i className="bx bx-user-plus" />
                    </div>
                    <div className="count_run_div">
                      <span className="count">11-25 </span>
                    </div>
                    <h5>Employees</h5>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="main_div_counter">
                    <div className="counter_icon_div main_div_counter2">
                      <i className="bx bxs-factory" />
                    </div>
                    <div className="count_run_div">
                      <span className="count">75 Sq mtr </span>
                    </div>
                    <h5>Infrastructure</h5>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="main_div_counter">
                    <div className="counter_icon_div">
                      <i className="bx bx-layer" />
                    </div>
                    <div className="count_run_div">
                      <span className="count">5 </span>
                    </div>
                    <h5>Top Export Countries</h5>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="main_div_counter">
                    <div className="counter_icon_div main_div_counter2">
                      <i className="bx bx-buildings" />
                    </div>
                    <div className="count_run_div">
                      <span className="count">2005 </span>
                    </div>
                    <h5>Year of Establishment</h5>
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </section>
        {/* counter end */}

        {/* ================== why choose us section start ===================== */}
        <section className="why_us_sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" />
            </div>
          </div>
        </section>
        {/* ================== why choose us section end ===================== */}
      </div>
      {/* Our Clients*/}
      <Galleryhome />
      <Testimonials />
    </>
  );
};

export default Home;
