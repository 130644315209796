import React from 'react'
import './products.css';
const Microtone = () => {
  return (
    <>
    <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Microtone</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* products */}
            <br />
            <section className="pro_details_section">
                <div className="container">
            {/* product start */}
            <div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Manual Rotary Microtome product */}
      <img src="https://3.imimg.com/data3/XV/FB/MY-3790006/rotary-microtome-500x500.jpg" className="img-fluid" alt="Manual Rotary Microtome" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="manualRotaryMicrotome">Manual Rotary Microtome</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 3.5 Lakh / Piece</td>
          </tr>
          <tr>
            <td>Section Thickness Setting</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>Mild Steel</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Industrial</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent" style={{ display: 'none' }}>
        <p>We hold expertise in offering our clients an immaculate range of Rotary Microtomes. These are designed for precise and accurate serial sectioning, which feed setting visible from front. This improved locking device and help in counter the balanced of wheel. For easy access to internal parts for cleaning and oiling, it is provided with light weight cover hinged at back. In order to prevent jamming, the automatic restricted total excursion of 28 mm is provided to disengaging the feed mechanism. The cam system removes the conventional cord system for feeding, which results in better section cutting. Further, it improves the efficiency of the machinery. The two levers have been provided that release knife holder for resetting angles. Further, the third lever releases the entire assembly for movement on slide way that is adjustable horizontally.</p>
        <p>Other Specifications:</p>
        <ul>
          <li>Feed is adjustable from 1 to 50 microns in steps of 1 micron</li>
          <li>Free up and down movement of object clamp</li>
          <li>Ball and flange object clamp provides flexible orientation of object piece</li>
          <li>Takes specimen upto 32 x 27 mm on stem of block holders</li>
          <li>Knife holder allows for 120 mm on 180 mm long knife</li>
          <li>Cutting angle is adjustable through 30 degrees</li>
          <li>Instrument Technical Specification:-</li>
          <li>Minimum Setting Value: 0.5 μm</li>
          <li>Trimming Thickness Setting Range: 1-60 μm</li>
          <li>Section Thickness Setting Range: 0.5-60 μm</li>
          <li>Setting Value: 0.5 μm Increment Step 0.5 μm</li>
          <li>2-20 μm Increment Step 1 μm</li>
          <li>20-60 μm Increment Step 5 μm</li>
        </ul>
        <p>Category: Health Medicine</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent');
        const button = document.getElementById('toggleButton');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton">Read More</button>
    </div>
  </div>
</div>
</div>
</section>
    </>
  )
}

export default Microtone
