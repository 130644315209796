import React from 'react'
import './products.css';
const TissueCassetteBaseMolds = () => {
  return (
    <>
      <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Tissue Cassette Base Molds</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* page title section end */}
            {/* product section start */}
            <br />
            <section className="pro_details_section">
                <div className="container">
     {/* product start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Tissue Cassette Base Molds product */}
      <img src="https://4.imimg.com/data4/JJ/VJ/MY-3790006/tissue-cassettes-base-molds-500x500.jpg" className="img-fluid" alt="Tissue Cassette Base Molds" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="tissueCassetteBaseMolds">Tissue Cassette Base Molds</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 500 / Piece</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Industrial</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>Stainless Steel</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>Silver</td>
          </tr>
          <tr>
            <td>Packaging Type</td>
            <td>Box</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
          <tr>
            <td>Shape</td>
            <td>Square</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent" style={{ display: 'none' }}>
        <p>R.K Tissue Cassettes Base Molds With the help of our efficient workforce, we are capable of providing our clients with a comprehensive range of Stainless Steel Base Molds. These steel base molds are particularly used with embedding cassettes. Embedding Cassettes are available in all sizes and made up of reusable and stainless steel</p>
        <p>Sizes: 7 x 7 x 5 mm, 15 x 15 x 5 mm, 24 x 24 x 5 mm, 30 x 24 x 5 mm, 37 x 24 x 5 mm, 7 x 7 x 6 mm, 15 x 15 x 6 mm, 24 x 24 x 6 mm, 30 x 24 x 6 mm, 37 x 24 x 6 mm, 7 x 7 x 7 mm, 15 x 15 x 7 mm, 24 x 24 x 7 mm, 30 x 24 x 7 mm, 37 x 24 x 7 mm</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent');
        const button = document.getElementById('toggleButton');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton">Read More</button>
    </div>
  </div>
</div>
{/* product end */}
            <br/>
</div>
</section>
    </>
  )
}

export default TissueCassetteBaseMolds
