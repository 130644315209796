import React from "react";
import { team } from "../data/team";
import "./testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Testimonialtop from "./testimonialtop";

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,// Remove next and previous buttons
    speed: 2000,
    slidesToShow: window.innerWidth <= 768 ? 1 : 3,
    slidesToScroll: window.innerWidth <= 768 ? 1 : 2,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000 // Set autoplay speed in milliseconds (e.g., 3000 = 3 seconds)
  };


  return (
    <>
      <h1 style={{ textAlign: 'center', marginTop: '10px', marginBottom: '-3px' }}> Testimonials</h1>


      <section className='services mb'>
        {/* <Back name='' title='' cover='https://info.vmgstudios.com/hubfs/AdobeStock_461901572.jpeg' /> */}

        <section className='team background'>
          <div className='container '>


            <Slider {...settings} className="team-slider">
              {team.map((val, index) => (
                <div className='box' key={index}>
                  <div className='details'>
                    <div className='img'>
                      <img src={val.cover} alt='' />
                      {/* <i className='fa-solid fa-circle-check'></i> */}
                    </div>
                    <h4>{val.name}</h4>
                    <label>{val.testimonial}</label>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </section>
    </>
  );
};

export default Testimonials;
