import React from 'react';
import './products.css';

const Hospitalequipments = () => {
    return (
        <>
            <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li>Our Products</li>
                                            <li>Hospital Laboratory Equipments</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="pro_details_section">
                <div className="container">
                    {/* product-1 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                <a href="https://5.imimg.com/data5/SELLER/Default/2024/2/388874047/YY/ND/ZI/9049015/embedding-cassette-clump-holder-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2024/2/388874047/YY/ND/ZI/9049015/embedding-cassette-clump-holder-500x500.jpg" className="img-fluid" alt="Embedding Cassette Clump Holder" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Embedding Cassette Clump Holder</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 35,000 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Automation Grade</td>
                                            <td>Automatic</td>
                                        </tr>
                                        <tr>
                                            <td>Frequency</td>
                                            <td>50/60 Hz</td>
                                        </tr>
                                        <tr>
                                            <td>Power Source</td>
                                            <td>Electric</td>
                                        </tr>
                                        <tr>
                                            <td>Voltage</td>
                                            <td>210V</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent1" style={{ display: 'none' }}>
                                    <p>We are the leading supplier and manufacturer of Embedding Cassette Clump Holders.</p>
                                    <h4>Features:</h4>
                                    <ul>
                                        <li>Tissue Specimen Clamp for Microtome, Paraffin Block Clamp or Embedding Cassettes Clamp</li>
                                        <li>Pathological consumable</li>
                                    </ul>
                                    <p><strong>Category:</strong> Health Medicine</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent1');
                                    const button = document.getElementById('toggleButton1');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton1">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-1 end */}
                    <br />
                    {/* product-2 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                <a href="https://5.imimg.com/data5/ANDROID/Default/2021/4/CR/UM/ZF/3790006/product-jpeg-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/ANDROID/Default/2021/4/CR/UM/ZF/3790006/product-jpeg-500x500.jpg" className="img-fluid" alt="Laboratary Embedding Ring" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Laboratary Embedding Ring</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 4 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>White</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Mild Steel</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent2" style={{ display: 'none' }}>
                                    <p><strong>Embedding Rings</strong> are suitable for holding and identifying tissue sample blocks and fit well in microtome chuck adapters. The etched writing surface on the ring is marked with an identification number and placed on top of the sample block. Additional paraffin is poured into the base mold to cement the ring onto the tissue block. The embedding ring securely holds the tissue sample in the microtome chuck adapter for sectioning and then identifies the sample while in storage.</p>
                                    <p><strong>Category:</strong> Health Medicine</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent2');
                                    const button = document.getElementById('toggleButton2');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton2">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-2 end */}
                    <br />
                    {/* product-3 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                <a href="https://5.imimg.com/data5/SELLER/Default/2023/12/371479286/JI/PO/FW/3790006/embedding-mold-500x500.jpeg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2023/12/371479286/JI/PO/FW/3790006/embedding-mold-500x500.jpeg" className="img-fluid" alt="Tissue Processing Cassettes" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Tissue Processing Cassettes</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 100 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Minimum Order Quantity</td>
                                            <td>1000 Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Automation Grade</td>
                                            <td>Automatic</td>
                                        </tr>
                                        <tr>
                                            <td>Power Source</td>
                                            <td>Electric</td>
                                        </tr>
                                        <tr>
                                            <td>Voltage</td>
                                            <td>210V</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent3" style={{ display: 'none' }}>
                                    <p><strong>Cassettes</strong> keep specimen safely submerged in liquid. Efficient flow-through slots. Snap-latch and hinge-lock design prevent early separation of base and lid and allows for one hand operation. Large labeling area on two sides of cassette. Anterior writing area is at 35° angle. Outside dimensions: 28 x 34mm Inside dimensions: 26 x 30 x 5mm.</p>
                                    <p><strong>Category</strong>: Health Medicine</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent3');
                                    const button = document.getElementById('toggleButton3');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton3">Read More</button>
                            </div>

                        </div>
                    </div>
                    {/* product-3 end */}
                    <br />
                    {/* product-4 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">

                                <img src="https://3.imimg.com/data3/DC/SL/MY-3790006/slide-warming-tables-500x500.jpg" className="img-fluid" alt="Slide Warming Table" />

                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Slide Warming Table</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 40,000 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>Semi Auto</td>
                                        </tr>
                                        <tr>
                                            <td>Surface Finish</td>
                                            <td>Paint Coated</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent03" style={{ display: 'none' }}>
                                    <p>We are engaged in offering an immaculate range of Slide Warming Tables. The melting point of wax provides maximum flattening without damaging the section by heating the slides at uniform temperature. The top plate of the offered range is mounted with an insulated metal frame and fitted with imported thermostat that controls surface temperature of 70°C. These are suitable to work on 220 V, single phase, 50 Hz and AC supply. In order to show the surface temperature, the depression has been provided on the top surface to the house with a thermometer.</p>
                                    <p>These are provided with the following:</p>
                                    <ul>
                                        <li>Pilot light</li>
                                        <li>Cord</li>
                                        <li>Plug without thermometer</li>
                                    </ul>
                                    <p><strong>Item Code</strong>: 517</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent03');
                                    const button = document.getElementById('toggleButton03');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton03">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-4 end */}
                    <br />

                    {/* product-4 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">

                                <img src="https://5.imimg.com/data5/SELLER/Default/2021/10/YQ/PF/GV/3790006/embedding-cassette-500x500.jpeg" className="img-fluid" alt="Embedding Cassette" />

                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Embedding Cassette</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 4 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>PVC</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>Red, White, Green and Yellow</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Shape</td>
                                            <td>Square</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent4" style={{ display: 'none' }}>
                                    <p><strong>R. K. Scientific Instrument Center</strong> has in its store large variety of Processing Cassettes made from quality material. These Products are available variety of colors & shapes.</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent4');
                                    const button = document.getElementById('toggleButton4');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton4">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-4 end */}
                    <br />
                    {/* product-5 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">

                                <img src="https://2.imimg.com/data2/DD/CC/MY-3790006/slidetra5-500x500.jpg" className="img-fluid" alt="Aluminium Slide Tray" />

                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Aluminium Slide Tray</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Product Price</td>
                                            <td>Get Latest Price</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>PVC</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>Brown</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Tray Shape</td>
                                            <td>Rectangular</td>
                                        </tr>
                                        <tr>
                                            <td>Tray Thickness</td>
                                            <td>1.5 mm</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent5" style={{ display: 'none' }}>
                                    <p>Useful for staining slides in flat position, made out of thick aluminum sheet by dia press system duly anodized and is useful in staining slides in flat position or for drying freshly prepared slides in vertical or flat position, usable on both sides.</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent5');
                                    const button = document.getElementById('toggleButton5');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton5">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-5 end */}
                    <br />
                    {/* product-6 start */}
                    {/* product-X start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                <img src="https://5.imimg.com/data5/SELLER/Default/2023/12/371479084/ZS/IG/ZL/3790006/embedding-mold-500x500.jpeg" className="img-fluid" alt="Aluminium Slide Tray" />
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Tissue Processing Capsules</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 20,000 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Minimum Order Quantity</td>
                                            <td>50 Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Hospital</td>
                                        </tr>
                                        <tr>
                                            <td>Automation Grade</td>
                                            <td>Automatic</td>
                                        </tr>
                                        <tr>
                                            <td>Frequency</td>
                                            <td>50/60 Hz</td>
                                        </tr>
                                        <tr>
                                            <td>Power Source</td>
                                            <td>Electric</td>
                                        </tr>
                                        <tr>
                                            <td>Voltage</td>
                                            <td>210V</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent6" style={{ display: 'none' }}>
                                    <p>Tissue Capsule Stainless Steel 28x28x9mm with sliding covers</p>
                                    <p>Tissue Capsules Stainless Steel 20x20x10 mm with sliding cover</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent6');
                                    const button = document.getElementById('toggleButton6');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton6">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-X end */}
                    <br />
                    {/* product-7 start */}

                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add your product image here */}
                                <img src="https://3.imimg.com/data3/MV/DM/MY-3790006/tissue-embedding-station-500x500.jpg" alt="Tissue Embedding Station" />
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Tissue Embedding Station</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 3 Lakh / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Size</td>
                                            <td>53 x 70 x 120 cm</td>
                                        </tr>
                                        <tr>
                                            <td>Model Name/Number</td>
                                            <td>TISSUE EMBENDDING</td>
                                        </tr>
                                        <tr>
                                            <td>USES</td>
                                            <td>LABORATORY</td>
                                        </tr>
                                        <tr>
                                            <td>TYPE</td>
                                            <td>SEMI</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent7" style={{ display: 'none' }}>
                                    <p>That can be positioned either left-to-right or right-to-left workflow. Depending of the preference of the operator. The ergonomic large working surface allows processing many samples at same time.</p>
                                    <p>Whole machine is made of M/s Steel temperature of molds and cassettes warming platform.</p>
                                    <p>Temperatures are controlled By Temperature Controllers and regulated, Independent 2 temperature control systems (6 systems are adjustable 30°C-80°C) The tissue embedding cassettes and base molds trays are removable and can be set at, The setting knobs allow to adjust the precise paraffin flow. paraffin tank Capacity – 5 Liter</p>
                                    <p>Note:- In paraffin has a additional timer for automatically on / off at any time and any day this is a new feature in paraffin disp</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent7');
                                    const button = document.getElementById('toggleButton7');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton7">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-Y end */}
                    <br />
                    {/* product-8 start */}

                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add your product image here */}
                                <img src="https://3.imimg.com/data3/SO/SJ/MY-3790006/metal-lid-casettes-500x500.jpg" alt="Metal Lid Cassettes" />

                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="classicsbowl">Metal Lid Cassettes</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 999 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                        <tr>
                                            <td>Surface Finish</td>
                                            <td>Paint Coated</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Metal</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent8" style={{ display: 'none' }}>
                                    <p>These are Stainless Steel Lids for use in place of the plastic lid Cassettes.</p>
                                    <p>Size: 28 mm width (1.1"), 33.5 mm length (1.35")</p>
                                    <p>Category: Health Medicine</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent8');
                                    const button = document.getElementById('toggleButton8');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton8">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-Z end */}
                    <br />

                </div>
            </section>
        </>
    );
};

export default Hospitalequipments;
