import React from 'react'
import './products.css'
const Tissuefloatingbath = () => {
  return (
    <>
      <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Tissue flotation bath</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* products */}
            <br />
            <section className="pro_details_section">
                <div className="container">
         {/* product-1 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Tissue Flotation Bath Digital Model product */}
      <img src="https://3.imimg.com/data3/VO/KD/MY-3790006/tissue-flotation-bath-in-digital-500x500.jpg" className="img-fluid" alt="Tissue Flotation Bath Digital Model" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="tissueFlotationBathDigitalModel">Tissue Flotation Bath Digital Model</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 30,000 / Piece</td>
          </tr>
          <tr>
            <td>Minimum Order Quantity</td>
            <td>2 Piece</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>Semi</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>Tissue Floating</td>
          </tr>
          <tr>
            <td>Country of Origin</td>
            <td>Made in India</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent1" style={{ display: 'none' }}>
        <p>R.K.Tissue Flotation Bath Rectangular digital. Flotation Bath model 518. The tissue flotation bath is a rectangular water bath designed with a surface ensuring high thermal conductivity rates and outstanding resistance to mechanical damage owing to its special plastic coating. Temperatures can be selected between ambient temperatures and 70 °C. Programmed as well as current temperatures are shown on a LED display while the selected temperature set value is memorized via a built-in battery backup system. To increase safety and reliability features an overheating protection system along with a stand-by mode. A flattening bath for paraffin sections with digital display and set value memory to stretch and flatten sectioned tissue specimens for all areas of biomedical research and routine histopathology diagnostics. Suitable to work on 220V, single phase, 50Hz, AC supply.</p>
        <p>Chamber Size: Dia 200x180 X Depth 70 x Rim 40</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent1');
        const button = document.getElementById('toggleButton1');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton1">Read More</button>
    </div>
  </div>
</div>
{/* product-1 end */}
<br />
{/* product-2 start */}
<div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Tissue Flotation Bath product */}
      <img src="https://5.imimg.com/data5/SELLER/Default/2024/2/388883716/IC/PY/BC/3790006/product-jpeg-500x500.jpeg" className="img-fluid" alt="Tissue Flotation Bath" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="tissueFlotationBath">Tissue Flotation Bath</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 30,000 / Piece</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>Tissue Floatation</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>White</td>
          </tr>
          <tr>
            <td>Uses</td>
            <td>Semi</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K.</td>
          </tr>
          <tr>
            <td>Country of Origin</td>
            <td>Made in India</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent2" style={{ display: 'none' }}>
        <p>We offer our clients a comprehensive range of Tissue Flotation Bath. These flotations are ideally suited for preparing distortion less and wrinkle free tissue specimen. The offered range is round in shape with double walled and single piece. The inner and outer chambers are made of aluminum thus inside anodized black and the outer surface finished in parrot green stoved enamel. In addition, the rim is provided for drying the wet slides. Moreover, EGO/JUMO helps in controlling temperature. Due to the German capillary types of thermostat, it ambient to 70°C that includes the accuracy of 2°C. Further, it is supplied with complete pilot light and cord. It is appropriate work on 220 V, single phase, 50 Hz and AC supply.</p>
        <p>Chamber Size: Dia depth rim 225 x 70 x 40</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent2');
        const button = document.getElementById('toggleButton2');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton2">Read More</button>
    </div>
  </div>
</div>
{/* product-2 end */}
<br />
</div>
</section>
    </>
  )
}

export default Tissuefloatingbath
