import React from 'react'
import { Link } from 'react-router-dom'
const Sidenavbar = () => {
  return (
    <>
      {/* =====================================Mobile navbar navigation start================================================================================================================ */}
      <div className="offcanvas offcanvas_scroll offcanvas-start d-block d-lg-none" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasScrollingLabel"><a href="/">
            <img src="../logo/logo.png" className="img-fluid" />
          </a></h5>
          <button type="button" data-bs-dismiss="offcanvas" aria-label="Close">
            <i className="bx bx-x" />
          </button>
        </div>
        <div className="offcanvas-body ">
          {/* Example single danger button */}
          <div className="mobile_navbar">
            <ul className="main_ul">
              <li><a href="/"><i className="bx bx-home"></i> Home</a></li>
              <li><a href="About"><i className="bx bx-info-circle"></i> About Us</a></li>
              {/* products li */}
              <li><a href="products">
                <i className="bx bx-box"></i> Products </a> <span data-bs-toggle="collapse" data-bs-target="#products-menu" className="bx bx-chevron-down" /></li>
              <div className="collapse" id="products-menu">
                <div className="card card-body profile_card_body">
                  <ul className="profile_ul">
                    <li><a href="/hospital-laboratory-equipments"><i className="bx bx-right-arrow-alt"></i> Hospital Laboratory Equipments</a></li>
                    <li><a href="/laboratory-equipments"><i className="bx bx-right-arrow-alt"></i> Laboratory Equipments</a></li>
                    <li><a href="/StorageCabinet"><i className="bx bx-right-arrow-alt"></i> Storage Cabinet</a></li>
                    <li><a href="/SlideCabinets"><i className="bx bx-right-arrow-alt"></i> Slide Cabinets</a></li>
                    <li><a href="/Tissuefloatingbath"><i className="bx bx-right-arrow-alt"></i> Tissue Flotation Bath</a></li>
                    <li><a href="/Waxbath"><i className="bx bx-right-arrow-alt"></i> Wax Bath</a></li>
                    <li><a href="/Tissuecassettebasemolds"><i className="bx bx-right-arrow-alt"></i> Tissue Cassette Base Molds</a></li>
                    <li><a href="/SlideStainingTray"><i className="bx bx-right-arrow-alt"></i> Slide Staining Tray</a></li>
                    <li><a href="/Microtome"><i className="bx bx-right-arrow-alt"></i> Microtome</a></li>
                    <li><a href="/CabinetCardHolder"><i className="bx bx-right-arrow-alt"></i> Cabinet Card Holder</a></li>
                    <li><a href="/GrossingKnife"><i className="bx bx-right-arrow-alt"></i> Grossing Knife</a></li>
                    <li><a href="/EmbeddingCassette"><i className="bx bx-right-arrow-alt"></i> Embedding Cassette</a></li>
                  </ul>
                </div>
              </div>
              {/*products drop li end */}
              {/* <li className="nav-item"><a className="nav-link" href="gallery"><i className="bx bx-images"></i> Gallery</a></li> */}
              <li className="nav-item"><a className="nav-link" href="Certificate"><i className="bx bx-file"></i> Certificates</a></li>
              <li className="nav-item"><a className="nav-link" href="sitemap"><i className="bx bx-sitemap"></i> Sitemap</a></li>
              <li className="nav-item"><a className="nav-link" href="testimonial"><i className="bx bx-star"></i> Testimonials</a></li>
              {/* <li className="nav-item"><a className="nav-link" href="brochure"><i className="bx bx-file"></i> Brochure </a></li> */}
              <li className="nav-item"><a className="nav-link" href="Contact"><i className="bx bx-phone"></i> Contact Us</a></li>
            </ul>
          </div>
          {/* contact us div */}
          {/* <div class="offcanvas_ti">
<h3>Contact Details</h3>
</div> */}
          <div className="offcanvas_conatct_div">
            {/* div number */}
            <p>
              <a href="tel:+91 9999401627"><i className="bx bx-phone-outgoing"></i> +91 9999401627</a>
              <a href="mailto:rksisc@gmail.com"> <i className="bx bx-mail-send"></i> rksisc@gmail.com</a>
            </p>
            {/* div number */}
          </div>
          {/* contact us div end */}
        </div>
      </div>
      {/* =====================================Mobile navbar navigation end================================================================================================================ */}
    </>
  )
}

export default Sidenavbar