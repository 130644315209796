import React from 'react'
import './products.css'

const Laboratoryequipment = () => {
    return (
        <>
            <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li>Our Products</li>
                                            <li>Laboratory Equipments</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="pro_details_section">
                <div className="container">

                    {/* product-1 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Tissue Embedding Mouldes product */}
                                <a href="https://5.imimg.com/data5/SELLER/Default/2023/12/371470373/HB/OD/ID/3790006/tissue-embedding-mouldes-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2023/12/371470373/HB/OD/ID/3790006/tissue-embedding-mouldes-500x500.jpg" className="img-fluid" alt="Tissue Embedding Mouldes" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="tissueEmbeddingMouldes">Tissue Embedding Mouldes</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 500 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Minimum Order Quantity</td>
                                            <td>50 Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Thickness</td>
                                            <td>2 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Stainless Steel</td>
                                        </tr>
                                        <tr>
                                            <td>Finish</td>
                                            <td>Polished</td>
                                        </tr>
                                        <tr>
                                            <td>Shaping Mode</td>
                                            <td>Square</td>
                                        </tr>
                                        <tr>
                                            <td>Shape</td>
                                            <td>Rectangular</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent1" style={{ display: 'none' }}>
                                    <h4>Features:</h4>
                                    <ul>
                                        <li>Made of Stainless Steel</li>
                                        <li>Sizes fit most cassettes.</li>
                                        <li>Superior thermal exchange.</li>
                                    </ul>
                                    <h4>Applications:</h4>
                                    <p>These molds are for all applications in specimen embedding with all styles of Embedding Rings and Cassettes. Manufactured from high-quality stainless steel for optimal thermal conductivity, the molds have a highly polished surface for easy paraffin block removal. Well corners are rounded for optimal paraffin ribboning.</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent1');
                                    const button = document.getElementById('toggleButton1');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton1">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-1 end */}
                    <br />
                    {/* product-2 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Biopsy Embedding Cassette product */}
                                <a href="https://3.imimg.com/data3/NE/HI/MY-3790006/tissue-embedding-cassettes-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://3.imimg.com/data3/NE/HI/MY-3790006/tissue-embedding-cassettes-500x500.jpg" className="img-fluid" alt="Biopsy Embedding Cassette" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="biopsyEmbeddingCassette">Biopsy Embedding Cassette</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 5 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>PVC</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent-2" style={{ display: 'none' }}>
                                    <h4>R.K Scientific - Biopsy Cassettes</h4>
                                    <p>Microbiopsy Cassettes are specially designed to accommodate extremely small specimens and tissue biopsies. These cassettes features a 0.3 mm pore size and have a separate compartment with larger holes for rapid fluid exchange to reduce flotation. The cassettes can easily be opened and closed with one hand.</p>
                                    <p>Molded from a special high density polymer, these patented cassettes keep specimens safely submerged in liquid and are totally resistant to the chemical action of histological solvents. They can be opened and closed as often as necessary and they always relock securely without danger of specimen loss. Anterior writing area is at a 45° angle to make the cassette more suitable to be used with automated cassette printers.</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent-2');
                                    const button = document.getElementById('toggleButton-2');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton-2">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-2 end */}
                    <br />
                    {/* product-3 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Tissue Processing Cassettes product */}
                                <a href="https://5.imimg.com/data5/SELLER/Default/2021/10/HZ/RR/ID/3790006/tissue-processing-cassettes-500x500.jpeg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2021/10/HZ/RR/ID/3790006/tissue-processing-cassettes-500x500.jpeg" className="img-fluid" alt="Tissue Processing Cassettes" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="tissueProcessingCassettes">Tissue Processing Cassettes</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 15 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Minimum Order Quantity</td>
                                            <td>10000 Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>PVC</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>Red, White, Green and Yellow</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Shape</td>
                                            <td>Square</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent-3" style={{ display: 'none' }}>
                                    <p>These High Quality Tissue Processing Cassettes in white colour made from quality material are provided by us. This Products are highly appreciated by our clients because of their less price & long life.</p>
                                    <p>Cassettes keep specimen safely submerged in liquid. Efficient flow-through slots. Snap-latch and hinge-lock design prevent early separation of base and lid and allows for one hand operation. Large labeling area on two sides of cassette. Anterior writing area is at 35° angle</p>
                                    <h4>Category : Health Medicine</h4>
                                    <h4>Additional Information:</h4>
                                    <ul>
                                        <li>Production Capacity: 100000 Per Month</li>
                                        <li>Delivery Time: 10 -15 Days</li>
                                    </ul>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent-3');
                                    const button = document.getElementById('toggleButton-3');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton-3">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-3 end */}
                    <br />
                    {/* product-4 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Embedding Mould Rings Cassettes product */}
                                <a href="https://5.imimg.com/data5/SELLER/Default/2023/12/371482191/GI/DA/HP/3790006/embedding-mould-rings-cassettes-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2023/12/371482191/GI/DA/HP/3790006/embedding-mould-rings-cassettes-500x500.jpg" className="img-fluid" alt="Embedding Mould Rings Cassettes" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="embeddingMouldRingsCassettes">Embedding Mould Rings Cassettes</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 2,000 / unit</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>multi</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrail</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>PVC</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent-6" style={{ display: 'none' }}>
                                    <p>We are instrumental in offering our clients an array of L-Molds: Embedding Molds. These are used for holding the wax block for taking sections on microtome. These offered range is stringently checked on varied parameters to ensure these products are durable and deliver optimum results.</p>
                                    <h4>Features:</h4>
                                    <ul>
                                        <li>Available in varied sizes such as:</li>
                                        <ul>
                                            <li>37 × 25 × 15 mm pair</li>
                                            <li>50 × 25 × 15 mm pair</li>
                                            <li>75 × 30 × 19 mm pair</li>
                                        </ul>
                                    </ul>
                                    <h4>Category : Health Medicine</h4>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent-6');
                                    const button = document.getElementById('toggleButton-6');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton-6">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-4 end */}
                    <br />
                    {/* product-5 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Downdraft Grossing Table product */}
                                <a href="https://5.imimg.com/data5/SELLER/Default/2024/2/388859904/PL/DH/QO/9049015/grossing-table-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2024/2/388859904/PL/DH/QO/9049015/grossing-table-500x500.jpg" className="img-fluid" alt="Downdraft Grossing Table" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="downdraftGrossingTable">Downdraft Grossing Table</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 5 Lakh / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Product Type</td>
                                            <td>Grossing Table</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Thickness</td>
                                            <td>2 Inch</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent-5" style={{ display: 'none' }}>
                                    <h4>R.K. Grossing Table</h4>
                                    <p>These surgical pathology work stations are useful for examining surgical specimens. The contaminated air & formalin vapors from work table is sucked out by built-in blowers. Provided with lighting system, swiveled-magnifier, SS table, Sink & Tap, metric ruler, shelf, drawers and storage compartment under table, Towel/Tissue paper stand and waste bin. The working area sides are made from acrylic material. Widely used, as it is very convenient for “Grossing Work in Histopathology”.</p>
                                    <h4>Working Area:</h4>
                                    <ul>
                                        <li>L × W × H</li>
                                        <ul>
                                            <li>4" × 3" × 3"</li>
                                            <li>6" × 3" × 3"</li>
                                            <li>5" × 2.5" × 3"</li>
                                        </ul>
                                    </ul>
                                    <h4>Optional Accessories:</h4>
                                    <ul>
                                        <li>Built in U.V. Germicidal Light</li>
                                        <li>Exhaust duct per running ft</li>
                                    </ul>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent-5');
                                    const button = document.getElementById('toggleButton-5');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton-5">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-5 end */}
                    <br />
                    {/* product-6 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Embedding Cassettes Molds product */}
                                <a href="https://4.imimg.com/data4/JT/LF/MY-3790006/embedding-cassettes-molds-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://4.imimg.com/data4/JT/LF/MY-3790006/embedding-cassettes-molds-500x500.jpg" className="img-fluid" alt="Embedding Cassettes Molds" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="embeddingCassettesMolds">Embedding Cassettes Molds</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 300 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Stainless Steel</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>Silver</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Shape</td>
                                            <td>Square</td>
                                        </tr>
                                        <tr>
                                            <td>Thickness</td>
                                            <td>2 mm</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent-4" style={{ display: 'none' }}>
                                    <h4>R.K Tissue Cassettes Base Molds</h4>
                                    <p>With the help of our efficient workforce, we are capable of providing our clients with a comprehensive range of Stainless Steel Base Molds. These steel base molds are particularly used with embedding cassettes. Embedding Cassettes are available in all sizes and made up of reusable and stainless steel</p>
                                    <h4>Sizes:</h4>
                                    <ul>
                                        <li>7 x 7 x 5 mm</li>
                                        <li>15 x 15 x 5 mm</li>
                                        <li>24 x 24 x 5 mm</li>
                                        <li>30 x 24 x 5 mm</li>
                                        <li>37 x 24 x 5 mm</li>
                                        <li>7 x 7 x 6 mm</li>
                                        <li>15 x 15 x 6 mm</li>
                                        <li>24 x 24 x 6 mm</li>
                                        <li>30 x 24 x 6 mm</li>
                                        <li>37 x 24 x 6 mm</li>
                                        <li>7 x 7 x 7 mm</li>
                                        <li>15 x 15 x 7 mm</li>
                                        <li>24 x 24 x 7 mm</li>
                                        <li>30 x 24 x 7 mm</li>
                                        <li>37 x 24 x 7 mm</li>
                                    </ul>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent-4');
                                    const button = document.getElementById('toggleButton-4');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton-4">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-6 end */}
                    <br />
                    {/* product-7 start */}
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Slide Staining Tray product */}
                                <a href="https://5.imimg.com/data5/SELLER/Default/2021/10/SV/GI/VS/3790006/slide-staining-tray-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2021/10/SV/GI/VS/3790006/slide-staining-tray-500x500.jpg" className="img-fluid" alt="Slide Staining Tray" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="slideStainingTray">Slide Staining Tray</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 29,999 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Home</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>Chuk</td>
                                        </tr>
                                        <tr>
                                            <td>Thickness</td>
                                            <td>2 mm</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent" style={{ display: 'none' }}>
                                    <p>Staining slide racks are constructed of durable stainless steel with long rails. They hold up to 30 slides and fits sinks or trays up to stain, rinse, drain and dry quickly and easily.</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent');
                                    const button = document.getElementById('toggleButton');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-8 end */}
                    <br />
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Wax Dispensing Machine product */}
                                <a href="https://3.imimg.com/data3/IR/PX/MY-3790006/wax-dispenser-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://3.imimg.com/data3/IR/PX/MY-3790006/wax-dispenser-500x500.jpg" className="img-fluid" alt="Wax Dispensing Machine" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="waxDispensingMachine">Wax Dispensing Machine</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 60,000 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Country of Origin</td>
                                            <td>Made in India</td>
                                        </tr>
                                        <tr>
                                            <td>Surface Finish</td>
                                            <td>Polished</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Power Source</td>
                                            <td>Electric</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent2" style={{ display: 'none' }}>
                                    <p>R.K. PARAFFIN DISPENSER DIGITAL TEMPERATURE CONTROLLER</p>
                                    <p>This has been made by latest technology and new design. Embalding can be done in this very quickly. On the top of this a S/S tank with double valve has been made for wax; the cap of which is 4 Ltr. There are heating elements inside with cap 1000 wt. which has been made by Kanthal wire. There is a Kok from inside to outside, with the help of which wax come out from inside. On the outside with the help of which wax come out from inside. On the outside of Kok heating elements have been fitted. There is a switch on the control panel which is switched on & off for only 7 minutes. After this wax began to come out from Kok and switched off a tank (1/2 Ltr.) of wax has been fitted on the working table. There is a control panel for controlling all these operations. Digital Temperature controller shows 80°C Temperature of main tank. There is a switch down side which makes digital on & off. Sun week fitted on control panel controls tank of wax on the working table at 80°C.</p>
                                    <p>Features :-</p>
                                    <ul>
                                        <li>This Dispenser is rust proof.</li>
                                        <li>Suitable to work on 220 V 2 Amp. Single phase 50 Hz AC supply.</li>
                                        <li>This is measured by 23x13x12.</li>
                                        <li>The temperature of Dispenser can be done negative & positive (-, +).</li>
                                    </ul>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent2');
                                    const button = document.getElementById('toggleButton2');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton2">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-8 end */}
                    <br />
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Grossing Blade Holder product */}
                                <a href="https://5.imimg.com/data5/SELLER/Default/2023/12/371493746/FE/VT/SG/3790006/surgical-grossing-knife-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2023/12/371493746/FE/VT/SG/3790006/surgical-grossing-knife-500x500.jpg" className="img-fluid" alt="Grossing Blade Holder" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="grossingBladeHolder">Grossing Blade Holder</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 10,000 / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Minimum Order Quantity</td>
                                            <td>5 Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Application</td>
                                            <td>Industrial</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>R.K.</td>
                                        </tr>
                                        <tr>
                                            <td>Blade Material</td>
                                            <td>Stainless Steel</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>Silver</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Type</td>
                                            <td>Box</td>
                                        </tr>
                                        <tr>
                                            <td>Thickness</td>
                                            <td>2.5 mm</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent3" style={{ display: 'none' }}>
                                    <p>R.K. Grossing Blade Holder</p>
                                    <p>Knife Plastic handle with socket stainless steel shaft. Very clever knife handles that fit ordinary microtome knife. In this way, you can reuse the microtome blade that you have needed for sectioning. Preferably used for cutting and dissection. Accessories, microtome knife blade.</p>
                                    <p>Additional Information:</p>
                                    <p>Delivery Time: 5-7 days</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent3');
                                    const button = document.getElementById('toggleButton3');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton3">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-9 end */}
                    <br />
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="disimg">
                                {/* Add the image URL for the Microtome Blade Holder product */}
                                <a href="https://2.imimg.com/data2/GT/LU/MY-3790006/microtome-blade-holder-500x500.jpg" data-fancybox="gallery">
                                    <img src="https://2.imimg.com/data2/GT/LU/MY-3790006/microtome-blade-holder-500x500.jpg" className="img-fluid" alt="Microtome Blade Holder" />
                                </a>
                                <div className="overlay_dis">
                                    <a href="/contact" className="popup-modal">Enquiry Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12">
                            <div className="prodistext">
                                <h3 id="microtomeBladeHolder">Microtome Blade Holder</h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Approx. Price</td>
                                            <td>Rs 1.6 Lakh / Piece</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>Blade No. 10</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Carbon Steel</td>
                                        </tr>
                                        <tr>
                                            <td>Usage/Application</td>
                                            <td>Cardiology</td>
                                        </tr>
                                        <tr>
                                            <td>Shapes</td>
                                            <td>Triangular</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>Microtome Blade Holder</td>
                                        </tr>
                                        <tr>
                                            <td>Type Of Cutting Edge</td>
                                            <td>Pointed</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="extraContent4" style={{ display: 'none' }}>
                                    <p>R.K. Knife Holder base</p>
                                    <p>Knife holder base, without lateral displacement, black & white -colored including locking lever</p>
                                    <p>Knife holder for high profile disposable blades (80 x 8 x 0.25 mm) Quick release clamping system. Clearance angle adjustment. Movable permanent knife guard.</p>
                                    <p>Pressure plate S, 22 °, for high profile blades</p>
                                    <p>M/s steel pressure plate</p>
                                    <p>Tungsten Carbide back plate</p>
                                </div>
                                <button onClick={() => {
                                    const content = document.getElementById('extraContent4');
                                    const button = document.getElementById('toggleButton4');
                                    if (content.style.display === 'none') {
                                        content.style.display = 'block';
                                        button.textContent = 'Read Less';
                                    } else {
                                        content.style.display = 'none';
                                        button.textContent = 'Read More';
                                    }
                                }} id="toggleButton4">Read More</button>
                            </div>
                        </div>
                    </div>
                    {/* product-10 end */}
                    <br />

                </div>
            </section>
        </>
    )
}

export default Laboratoryequipment
