
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Header from './components/Header';
import './pages/style.css';
import './pages/responsive.css';
import Footer from './components/Footer';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Aboutus from './pages/Aboutus';
import Contactus from './pages/Contactus';
import ScrollToTop from './components/Scrolltop';
import testimonialtop from './pages/testimonialtop';
import Sitemap from './pages/Sitemap';
import Gallery from './pages/Gallery';
import Brochers from './pages/Brochers';
import Product from './pages/Product';
import Hospitalequipments from './Products/Hospitalequipments';
import Laboratoryequipment from './Products/Laboratoryequipment';
import StorageCabinets from './Products/StorageCabinets';
import SlideCabinets from './Products/SlideCabinets';
import Tissuefloatingbath from './Products/Tissuefloatingbath';
import Waxbath from './Products/Waxbath';
import Tissuecassettebasemolds from './Products/TissueCassetteBaseMolds';
import SlideStainingTray from './Products/SlideStainingTray';
import Microtone from './Products/Microtone';
import CabinetCardHolder from './Products/CabinetCardHolder';
import GrossingKnife from './Products/GrossingKnife';
import EmbeddingCassette from './Products/EmbeddingCassette';
import Certificate from './pages/Certificate';

function App() {
 
  return (
    <>
  
  <BrowserRouter>
  <Header/>
  <ScrollToTop/>
  <Routes>
    <Route path='/' Component={Home}/>
    <Route path='/About' Component={Aboutus}/>
    <Route path='/Contact' Component={Contactus}/>
    <Route path='*' element={<h1>404 Not Found</h1>}/>
    <Route path='/testimonial' Component={testimonialtop}/>
    <Route path='/sitemap' Component={Sitemap}/>
    <Route path='/gallery' Component={Gallery}/>
    <Route path='/brochure' Component={Brochers}/>
    <Route path='/products' Component={Product}/>
{/* products */}
    <Route path='/Certificate' Component={Certificate}/>
    <Route path='/hospital-laboratory-equipments' Component={Hospitalequipments}/>
    <Route path='/laboratory-equipments' Component={Laboratoryequipment}/>
    <Route path='/StorageCabinet' Component={StorageCabinets}/>
    <Route path='SlideCabinets' Component={SlideCabinets}/>
    <Route path='Tissuefloatingbath' Component={Tissuefloatingbath}/>
    <Route path='Waxbath' Component={Waxbath}/>
    <Route path='Tissuecassettebasemolds' Component={Tissuecassettebasemolds}/>
    <Route path='SlideStainingTray' Component={SlideStainingTray}/>
    <Route path='Microtome' Component={Microtone}/>
    <Route path='CabinetCardHolder' Component={CabinetCardHolder}/>
    <Route path='GrossingKnife' Component={GrossingKnife}/>
    <Route path='EmbeddingCassette' Component={EmbeddingCassette}/>
  </Routes>
  <Footer/>
  </BrowserRouter>
  
    </>
  );
}

export default App;
