import React from 'react'
import './products.css';
const EmbeddingCassette = () => {
  return (
    <>
    <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Embedding Cassette</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* products */}
            <br />
            <section className="pro_details_section">
                <div className="container">
            {/* product start */}
            <div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Tissue Embedded Cassette product */}
      <img src="https://5.imimg.com/data5/SELLER/Default/2022/10/ET/ZL/GT/3790006/new-product-500x500.jpeg" className="img-fluid" alt="Tissue Embedded Cassette" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="tissueEmbeddedCassette">Tissue Embedded Cassette</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 3 / Piece</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Industrial</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>Multi</td>
          </tr>
          <tr>
            <td>Packaging Type</td>
            <td>BOX</td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>R.K</td>
          </tr>
          <tr>
            <td>Shape</td>
            <td>RECTANGULAR</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent" style={{ display: 'none' }}>
        <p>R.K. slide tray cabinet Closed pack mannerFor keeping 75x75mm slide in vertical position one after the other of steel attractive spray finish. The cabinet has 480x75mm drawer and teas slide in vertical position. Each drawer moves in a slot and is completely removable for easy lifting.</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent');
        const button = document.getElementById('toggleButton');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton">Read More</button>
    </div>
  </div>
</div>
<br/>
</div>
</section>
    </>
  )
}

export default EmbeddingCassette
