import React from 'react'

const Brochers = () => {
  return (
    <div>
       <section className="page_title_mkp_section">
          <div className="page_ti_overly_mkp_div">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                  <div className="page_title_tax_mkp_main_div">
                    <div className="page_title_tax_mkp_div">
                      <h1> Brochure</h1>
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li>Brochure</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </section>
      <h1> Brochers</h1>
    </div>
  )
}

export default Brochers
