export const team = [
  {
    name: "Ravi Kumar",
    testimonial: "R. K. Scientific provided us with high-quality laboratory equipment that significantly improved our workflow.",
    cover: "https://cdn-icons-png.freepik.com/512/3674/3674939.png",
  },
  {
    name: "Sunita Sharma",
    testimonial: "R. K. Scientific is our go-to for all our laboratory equipment needs. Their products are reliable and their customer service is excellent.",
    cover: "https://cdn-icons-png.freepik.com/512/3674/3674939.png",
  },
  {
    name: "Amit Patel",
    testimonial: "We purchased a wax bath from R. K. Scientific and it has been working flawlessly. Highly recommend their products.",
    cover: "https://cdn-icons-png.freepik.com/512/3674/3674939.png",
  },
  {
    name: "Geeta Singh",
    testimonial: "The slide staining tray we got from R. K. Scientific has been a great addition to our lab. It's easy to use and clean.",
    cover: "https://cdn-icons-png.freepik.com/512/3674/3674939.png",
  },
  {
    name: "Vijay Verma",
    testimonial: "R. K. Scientific is our go-to for all our laboratory equipment needs. Their products are reliable and their customer service is excellent.",
    cover: "https://cdn-icons-png.freepik.com/512/3674/3674939.png",
  },
];