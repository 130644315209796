import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";
import Sidenavbar from './Sidenavbar';
const Header = () => {
  return (
    <>
      
        {/* <section className="top_bar_sec">
          <div className="conatiner" />
        </section> */}
        {/* navbar  */}
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src="../logo/logo.png" alt='RkScientific' className="img-fluid" />
            </Link>
            {/* phone view icons s */}
            <div className="phone_view_icons_div">

              {/* <Link to="tel:+91-9810347240"> <i className="bx bx-phone-call" /> </Link> */}
              <Link className="offcanvas_btn bx bx-menu" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" />
            </div>
            {/* end */}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto">
                <li className="nav-item"><Link className="nav-link" to="/"><i className="bx bx-home"></i> Home</Link></li>
                <li className="nav-item"><Link className="nav-link" to="About"><i className="bx bx-info-circle"></i> About Us</Link> </li>
                {/* dropdwon menu */}
                <li className="nav-item dropdown"><Link className="nav-link" to="/products"><i className="bx bx-box"></i> Products <i className="bx bx-chevron-down" /> </Link>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item long-text" to="/hospital-laboratory-equipments"><i className="bx bx-right-arrow-alt"></i> Hospital Laboratory Equipments</Link></li>
                    <li><Link className="dropdown-item" to="/laboratory-equipments"><i className="bx bx-right-arrow-alt"></i> Laboratory Equipments</Link></li>
                    <li><Link className="dropdown-item" to="/StorageCabinet"><i className="bx bx-right-arrow-alt"></i> Storage Cabinet</Link></li>
                    <li><Link className="dropdown-item" to="/SlideCabinets"><i className="bx bx-right-arrow-alt"></i> Slide Cabinets</Link></li>
                    <li><Link className="dropdown-item" to="/Tissuefloatingbath"><i className="bx bx-right-arrow-alt"></i> Tissue Flotation Bath</Link></li>
                    <li><Link className="dropdown-item" to="/Waxbath"><i className="bx bx-right-arrow-alt"></i> Wax Bath</Link></li>
                    <li><Link className="dropdown-item" to="/Tissuecassettebasemolds"><i className="bx bx-right-arrow-alt"></i> Tissue Cassette Base Molds</Link></li>
                    <li><Link className="dropdown-item" to="SlideStainingTray"><i className="bx bx-right-arrow-alt"></i> Slide Staining Tray</Link></li>
                    <li><Link className="dropdown-item" to="Microtome"><i className="bx bx-right-arrow-alt"></i> Microtome</Link></li>
                    <li><Link className="dropdown-item" to="CabinetCardHolder"><i className="bx bx-right-arrow-alt"></i> Cabinet Card Holder</Link></li>
                    <li><Link className="dropdown-item" to="GrossingKnife"><i className="bx bx-right-arrow-alt"></i> Grossing Knife</Link></li>
                    <li><Link className="dropdown-item" to="EmbeddingCassette"><i className="bx bx-right-arrow-alt"></i> Embedding Cassette</Link></li>
                  </ul>
                </li>
                {/* <li className="nav-item"><Link className="nav-link" to="gallery"><i className="bx bx-photo-album"></i> Gallery</Link> </li> */}
                <li className="nav-item"><Link className="nav-link" to="testimonial"><i className="bx bx-user"></i> Testimonials</Link> </li>
                <li className="nav-item"><Link className="nav-link" to="Certificate"><i className="bx bx-file"></i> Certificates</Link> </li>
                <li className="nav-item"><Link className="nav-link" to="sitemap"><i className="bx bx-sitemap"></i> Sitemap </Link></li>
                <li className="nav-item"><Link className="nav-link" to="Contact"><i className="bx bx-phone"></i> Contact Us</Link></li>
              </ul>
            </div>
            <ul className="navbar-nav navbar_num_xl m-auto">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <Link to="tel:+91 99994 01627" className="bx bx-phone-call bx-tada" />
                </div>
                <div className="flex-grow-1 ms-2">
                  <h5>Call us</h5>
                  <Link to="tel:+91 99994 01627">+91 99994 01627</Link>
                </div>
              </div>
            </ul>
          </div>
        </nav>
        {/* navbar end */}
      

      {/* sidenavbar start */}
      <Sidenavbar />
      {/* sidenavbar end */}

    </>
  )
}

export default Header



