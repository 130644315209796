import React from 'react'
import Testimonials from './Testimonials'

const testimonialtop = () => {
  return (
    <>
        <Testimonials />
    </>
  )
}

export default testimonialtop
