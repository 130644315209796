import React from 'react'
import './Sitemap.css';
const Sitemap = () => {
  return (
    <>
       <section className="page_title_mkp_section">
          <div className="page_ti_overly_mkp_div">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                  <div className="page_title_tax_mkp_main_div">
                    <div className="page_title_tax_mkp_div">
                      {/* <h1> SiteMap</h1> */}
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li>SiteMap</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </section>
    {/* sitemap */}
    <div>
        {/*  */}
        <section className="site_map_navi">
          <div className="container">
            {/*  */}
            <div className="site_map_nav">
              <h2>Useful Links</h2>
              <div className="button-row">
                <a href="/" className="site-map-btn">
                  Home
                </a>
                <a href="about" className="site-map-btn">
                  About Us
                </a>
                <a href="testimonial" className="site-map-btn">
                  Testimonials
                </a>
              </div>
              <div class="button-row">
                <a href="products" className="site-map-btn">
                  Products
                </a>
                <a href="Certificate" className="site-map-btn">
                  Certificates
                </a>
                
                <a href="Contact" className="site-map-btn">
                  Contact Us
                </a>
              </div>
            </div>

            {/*  */}
          </div>
        </section>
        {/* our product start */}
        <section className="sitemap_section">
          <div className="container">
            <div className="sitemap_head_ti">
              <h2>Products</h2>
            </div>
            <div className="row">
              {/* col */}
            <div className="col-lg-6">
  <div className="sitemap_ul_div">
    <ul>
      <li><a href="/hospital-laboratory-equipments"><i className="bx bx-right-arrow-alt"></i> Hospital Laboratory Equipments</a></li>
      <li><a href="/laboratory-equipments"><i className="bx bx-right-arrow-alt"></i> Laboratory Equipments</a></li>
      <li><a href="/StorageCabinet"><i className="bx bx-right-arrow-alt"></i> Storage Cabinet</a></li>
      <li><a href="/SlideCabinets"><i className="bx bx-right-arrow-alt"></i> Slide Cabinets</a></li>
      <li><a href="/Tissuefloatingbath"><i className="bx bx-right-arrow-alt"></i> Tissue Flotation Bath</a></li>
      <li><a href="/Waxbath"><i className="bx bx-right-arrow-alt"></i> Wax Bath</a></li>
    </ul>
  </div>
</div>
{/* col */}
<div className="col-lg-6">
  <div className="sitemap_ul_div">
    <ul>
     
      <li><a href="/Tissuecassettebasemolds"><i className="bx bx-right-arrow-alt"></i> Tissue Cassette Base Molds</a></li>
      <li><a href="/SlideStainingTray"><i className="bx bx-right-arrow-alt"></i> Slide Staining Tray</a></li>
      <li><a href="/Microtome"><i className="bx bx-right-arrow-alt"></i> Microtome</a></li>
      <li><a href="/CabinetCardHolder"><i className="bx bx-right-arrow-alt"></i> Cabinet Card Holder</a></li>
      <li><a href="/GrossingKnife"><i className="bx bx-right-arrow-alt"></i> Grossing Knife</a></li>
      <li><a href="/EmbeddingCassette"><i className="bx bx-right-arrow-alt"></i> Embedding Cassette</a></li>
    </ul>
  </div>
</div>
            </div>
          </div>
        </section>

        {/*  */}
      </div>
    </>
  )
}

export default Sitemap
