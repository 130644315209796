import React from 'react'
import './Footer.css';
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div>
        <footer className="footer">
          <div className="footer_overlay">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="footer_about_div" data-aos="fade-down" data-aos-duration={800} data-aos-offset={0}>
                    <div className="footer_logo_div">
                      <Link to="/"><img src="../logo/logo.png" alt className="img-fluid" /></Link>
                    </div>
                    <p>RK Scientific is a dynamic force in the scientific industry, specializing in the manufacturing and distribution of high-quality scientific equipment.... <Link to="about">Read More</Link></p>

                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti" data-aos="fade-down" data-aos-duration={1200} data-aos-offset={0}>
                    <h5> Quick Links</h5>
                    <ul>
                    <li className="nav-item"><Link className="nav-link" to="/"><i className="bx bx-home"></i> Home</Link></li>
                <li className="nav-item"><Link className="nav-link" to="About"><i className="bx bx-info-circle"></i> About Us</Link> </li> 
                <li className="nav-item "><Link className="nav-link" to="/products"><i className="bx bx-box"></i> Products </Link> </li>
                    {/* <li className="nav-item"><Link className="nav-link" to="testimonial"><i className="bx bx-user"></i> Testimonials</Link> </li> */}
                <li className="nav-item"><Link className="nav-link" to="sitemap"><i className="bx bx-sitemap"></i> Sitemap </Link></li>
                <li className="nav-item"><Link className="nav-link" to="Contact"><i className="bx bx-phone"></i> Contact Us</Link></li>

                    </ul>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti" data-aos="fade-up" data-aos-duration={1400} data-aos-offset={0}>
                    <h5> Contact Us</h5>
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bx-map" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div">
                          <p>Plot No. 13A, C-519, Shiv Vihar, Rishal Garden, Najafgarh Road, Nilothi, New Delhi - 110041, Delhi, India</p>
                        </div>
                      </div>
                    </div>
                    {/* media div end */}
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bxs-phone-call" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div">
                          <Link to="tel:+91 9999401627"> +91 9999401627</Link>
                          
                          <Link to="tel:+91 9899461627"> +91 9899461627</Link>

                        </div>
                      </div>
                    </div>
                    {/* media div end */}
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0"> 
                        <i className="bx bx-envelope-open" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div">
                          <Link to="mailto:rksisc@gmail.com">  rksisc@gmail.com</Link>

                        </div>
                      </div>
                    </div>
                    {/* media div end */}
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti" data-aos="fade-up" data-aos-duration={1000} data-aos-offset={0}>
                    <h5> Location</h5>
                    <p>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3501.0363590898587!2d77.04805507550176!3d28.658629975650456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM5JzMxLjEiTiA3N8KwMDMnMDIuMyJF!5e0!3m2!1sen!2sin!4v1715411208396!5m2!1sen!2sin"
                        width="100%"
                        height="auto"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </p>
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </footer>
        {/* ===================================copyright section start================= */}
        <section className="copyright_section">
          <div className="container">
            <div className="row">
              {/* col start  */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <p>Copyright © 2024 RkScientific Privated Limited. All Right Reserved.</p>
              </div>
              {/* col end */}
            </div>
          </div>
        </section>
        {/* ===================================copyright section end================= */}
      </div>


    </>
  )
}

export default Footer
