import React from 'react'
import './products.css';
const CabinetCardHolder = () => {
  return (
    <>
    <section className="page_title_mkp_section">
                <div className="page_ti_overly_mkp_div">
                    <div className="container">
                        <div className="row">
                            {/* col start */}
                            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                                <div className="page_title_tax_mkp_main_div">
                                    <div className="page_title_tax_mkp_div">
                                        {/* <h1> Our Products</h1> */}
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href='/products'>Our Products</a></li>
                                            <li>Cabinet Card Holder</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* col end */}
                        </div>
                    </div>
                </div>
            </section>
            {/* products */}
            <br />
            <section className="pro_details_section">
                <div className="container">
            {/* product start */}
            <div className="row">
  <div className="col-lg-5 col-md-6 col-12">
    <div className="disimg">
      {/* Add the image URL for the Cabinet Card Holder product */}
      <img src="https://5.imimg.com/data5/JY/GW/MY-3790006/20170323_172820-500x500.jpg" className="img-fluid" alt="Cabinet Card Holder" />
      <div className="overlay_dis">
        <a href="/contact" className="popup-modal">Enquiry Now</a>
      </div>
    </div>
  </div>
  <div className="col-lg-7 col-md-6 col-12">
    <div className="prodistext">
      <h3 id="cabinetCardHolder">Cabinet Card Holder</h3>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td>Approx. Price</td>
            <td>Rs 12 / Piece</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>Card Holders</td>
          </tr>
          <tr>
            <td>Usage/Application</td>
            <td>Industrial</td>
          </tr>
          <tr>
            <td>Color</td>
            <td>Silver</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>4 Inch</td>
          </tr>
          <tr>
            <td>Material</td>
            <td>Stainless Steel</td>
          </tr>
          <tr>
            <td>Shape</td>
            <td>Rectangular</td>
          </tr>
        </tbody>
      </table>
      <div id="extraContent" style={{ display: 'none' }}>
        <p>Cabinet Label Tag Frame Pull Handle Name Card Holder</p>
        <p>Made of CRC Mild Steel with highly hayley electropolish</p>
        <p>For our most popular line of label holders several different options are available to fit your individual box/cabinet/drawer need.</p>
        <p>Size: L x 67mm H x30mm</p>
      </div>
      <button onClick={() => {
        const content = document.getElementById('extraContent');
        const button = document.getElementById('toggleButton');
        if (content.style.display === 'none') {
          content.style.display = 'block';
          button.textContent = 'Read Less';
        } else {
          content.style.display = 'none';
          button.textContent = 'Read More';
        }
      }} id="toggleButton">Read More</button>
    </div>
  </div>
</div>
<br/>
</div>
</section>
    </>
  )
}

export default CabinetCardHolder
