import React from 'react';
// import { Link } from "react-router-dom";
import img from '../banner/about.png';

const Aboutus = () => {

  return (
    <>

      <div>
        {/* pagte titile section start  */}
        <section className="page_title_mkp_section">
          <div className="page_ti_overly_mkp_div">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                  <div className="page_title_tax_mkp_main_div">
                    <div className="page_title_tax_mkp_div">
                      {/* <h1> About Us</h1> */}
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li>About Us</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </section>
        {/* pagte titile section end */}
        {/* about us section start */}
        <section className="about_us_section">
          <div className="container">

            <div className="row">
              {/* col start */}
              <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                <div className="about_img_main_div" data-aos="fade-up" data-aos-duration={1000} data-aos-offset={0}>
                  <div className="about_vd_div">
                    <img src={img} alt="About Us" style={{width: "100%"}} />
                  </div>
                </div>
              </div>
              {/* col end */}
              {/* col start */}
              <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                <div className="about_content_div" data-aos="fade-down" data-aos-duration={1600} data-aos-offset={0}>
                  <h1>Welcome to<span> Rk Scientific</span> </h1>
                  <h3 style={{color: '#f03637'}}>Manufacturer, Wholesaler, Trader And Exporter of</h3>
                  <h4> Hospital Laboratory Equipments, Slide Cabinets, Wax Bath, Slide Staining Tray, etc.</h4>
                  <p>Established in the year 2005, We, “R. K. Scientific Instrument Centre”, are a prominent Manufacturer, Wholesaler, Trader And Exporter of Hospital Laboratory Equipments, Slide Cabinets, Wax Bath, Slide Staining Tray, etc. This entire range of products is manufactured using finest grade raw material procured from vendors of the market.</p>
                  <b>Quality Assurance</b>
                  <p>These equipment are rigorously tested on varied quality parameters before the dispatch to ensure that these are in conformation with industry standards & norms. These equipment are widely used in various medical centers, hospitals, clinics and nursing homes.</p>

                </div>
              </div>
              {/* col end */}
              {/* col s */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="about_content_div" data-aos="fade-up" data-aos-duration={1600} data-aos-offset={0}>
                  <h3>Our Team</h3>
                  <b>Expert Team</b>
                  <p>We offer them a flawless array of products for which, we have hired a team of experts, which develops our products in compliance with the global quality standards. Our firm believes in maintaining a close relation with the clients so as earn goodwill in the industry. The widespread manufacturing unit is equipped with the essential machines and tools required in the production process.</p>
                  <b>Guidance</b>
                  <p>Under the guidance of our mentor, 'Mr. Rajesh Sharma', we have been able to gain a formidable position in the industry. Owing to his ethical business practices, we have garnered a huge client-base in the domestic market.</p>
                
                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </section>
        {/* about us section end */}
        {/* ====================why choose us section================= */}
        <section className="why_choose_us_section">
          <div className="container">
            <div className="row align-items-center">
              {/* col s */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div
                  className="why_choose_txt"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-offset={1}
                  data-aos-duration={800}
                >
                  <h4>Why Choose Us?</h4>
                  <p>
                    <b>
                      Our company always believes in offering high grade quality
                      products to our valued clients all across the market.
                    </b>
                  </p>
                </div>
                {/* why_us flx div */}
                <div
                  className="why_choose_us_div_flx"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-offset={1}
                  data-aos-duration={1000}
                >
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bx bx-building-house" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="why_us_div_txt_div">
                        <h5>Infratech</h5>
                        <p>
                          Our infrastructure is equipped with the latest
                          technology to produce high-quality hospital equipment.
                          We continuously upgrade our infrastructure to meet the
                          rising demands in the medical industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bx bx-comment-detail" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="why_us_div_txt_div">
                      <h5>Commitment</h5>
                        <p>
                          We are committed to increasing efficiency and raising
                          the standard of living. We stand by our products and
                          services and ensure they meet the expectations of our
                          clients and patients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* end */}
                {/* why_us flx div */}
                <div
                  className="why_choose_us_div_flx"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                  data-aos-offset={1}
                  data-aos-duration={500}
                >
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="bx bx-unite" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div className="why_us_div_txt_div">
                        <h5>Quality Assurance</h5>
                        <p>
                          We ensure all our hospital products and medical
                          accessories meet the highest quality standards. Our
                          approach is focused on patient's health and safety,
                          and we strive to provide products that aid in better
                          patient outcomes and reduced risks.
                        </p>
                        
                      </div>
                    </div>
                  </div>
                </div>
                {/* end */}
              </div>
              {/* col end */}
              {/* col s */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="why_us_img_div">
                  <img
                    src="https://5.imimg.com/data5/SELLER/Default/2024/2/388874047/YY/ND/ZI/9049015/embedding-cassette-clump-holder-500x500.jpg"
                    alt
                    className="img-fluid"
                  />

                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </section>
       
      </div>

    </>
  )
}

export default Aboutus
