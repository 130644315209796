import React, { useEffect } from 'react';
import './Certificate.css';
import img1 from './Certificateimg/001.jpg';
import img2 from './Certificateimg/002.jpg';
import img3 from './Certificateimg/003.jpg';
import img4 from './Certificateimg/004.jpg';
import img5 from './Certificateimg/005.jpg';
import img6 from './Certificateimg/006.jpg';
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const Certificate = () => {
  useEffect(() => {
    Fancybox.bind("[data-fancybox='gallery']", {
      // Custom FancyBox options can go here
    });
  }, []);

  return (
    <>
      {/* page title section start */}
      <section className="page_title_mkp_section">
        <div className="page_ti_overly_mkp_div">
          <div className="container">
            <div className="row">
              {/* col start */}
              <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                <div className="page_title_tax_mkp_main_div">
                  <div className="page_title_tax_mkp_div">
                    {/* <h1> About Us</h1> */}
                    <ul>
                      <li><a href="/">Home</a></li>
                      <li>Certificates</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </div>
      </section>
      {/* page title section end */}

      {/* product page */}
      <section>
        <div className="container">
          <div className="Certificates_head">
            <h3 className="text-center"></h3>
          </div>
          {/* */}
          <div className="row">

            <div className="col-md-4 col-lg-4 col-12">
              <div className="Certificates_txt">
                <h3>ISO CERTIFICATE</h3>
                <a href={img2} data-fancybox="gallery"><img src={img2} className="img-fluid" alt="ISO Certificate 2" /></a>
              </div>
            </div>

            <div className="col-md-4 col-lg-4 col-12">
              <div className="Certificates_txt">
                <h3>MSME CERTIFICATE</h3>
                <a href={img1} data-fancybox="gallery"><img src={img1} className="img-fluid" alt="ISO Certificate 1" /></a>
              </div>
            </div>

            <div className="col-md-4 col-lg-4 col-12">
              <div className="Certificates_txt">
                <h3>ISO CERTIFICATE</h3>
                <a href={img3} data-fancybox="gallery"><img src={img3} className="img-fluid" alt="MSME Certificate" /></a>
              </div>
            </div>

            <div className="col-md-4 col-lg-4 col-12">
              <div className="Certificates_txt">
                <h3>REGISTRATION CERTIFICATE</h3>
                <a href={img4} data-fancybox="gallery"><img src={img4} className="img-fluid" alt="ISO Certificate 3" /></a>
              </div>
            </div>

            <div className="col-md-4 col-lg-4 col-12">
              <div className="Certificates_txt">
                <h3>CE CERTIFICATE </h3>
                <a href={img5} data-fancybox="gallery"><img src={img5} className="img-fluid" alt="Importer-Exporter Certificate" /></a>
              </div>
            </div>

            <div className="col-md-4 col-lg-4 col-12">
              <div className="Certificates_txt">
                <h3>GMP CERTIFICATE</h3>
                <a href={img6} data-fancybox="gallery"><img src={img6} className="img-fluid" alt="CE Certificate" /></a>
              </div>
            </div>
          </div>
          {/* */}
        </div>
      </section>
      {/* product */}
    </>
  );
}

export default Certificate;
